/* eslint-disable */
import React, { useState } from 'react';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  TextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useSnackbar } from 'notistack';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Tooltip from '@material-ui/core/Tooltip';
import Niftron from "src/baas/config";
import CryptingSecret from 'src/components/CryptingSecret';

const BlockchainAccount = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [authUser, setAuthUser] = React.useState(null);

  React.useEffect(() => {
    Niftron.user.onAuthStateChanged(
      authUser => {
        setAuthUser(authUser)
      },
      err => {
        if (err) {
          console.log(err)
        }
      }
    );
  }, []);
  const copyMessage = (val) => {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >{authUser &&
      <Card>
        <CardContent>
          <Box mb={5}>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              Developer Credentials
                    </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              You will find your credentials here.</Typography><Divider />
          </Box>
          <Box mb={3}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              The following is the Developer Public Key.
                    </Typography>
            <TextField
              fullWidth
              label="Developer Public Key"
              margin="normal"
              name="developerPublicKey"
              value={authUser.publicKey}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <Tooltip title="Copy" placement="top">
                    <Button onClick={e => {
                      e.preventDefault()
                      copyMessage(authUser.publicKey)
                      enqueueSnackbar("Developer Public Key is copied to clipboard", { variant: "info" });

                    }}> <FileCopyIcon /> </Button>
                  </Tooltip>
                </InputAdornment>,
              }}
            />
          </Box>
          <Box mb={3}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              The following is the Developer Secret Key.
                    </Typography>
            <CryptingSecret
              encryptedSecret={"########################################################"}
              label={"Developer Secret Key"} name={"developerSecretKey"} />
          </Box>
        </CardContent>
      </Card>}
    </Box>

  );
};

export default BlockchainAccount;
