// /* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Checkbox,
    FormHelperText,
    Link,
    TextField,
    Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Dropzone from 'react-dropzone';
import sha256 from "sha256";
import Niftron from "src/baas/config";
import { Keypair } from 'stellar-sdk';
import PropTypes from 'prop-types';
import Joyride, { STATUS } from 'react-joyride';
// import './styles.css';
import Tooltip from '@material-ui/core/Tooltip';
import { niftronSigninPasswordDemo, niftronSigninSecretDemo } from "src/demos/Demo.js"

import Fab from '@material-ui/core/Fab';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.dark,
        padding: theme.spacing(2, 4, 3),
    },
}));

const NiftronSignerModal = ({ authTypeInput, open, setOpen, encryptedSecret, publicKey, onSecretKey, hideAutoSign }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [authType, setAuthType] = React.useState(authTypeInput);
    const [fileError] = React.useState(false);

    const [helpers, setHelpers] = React.useState(null);

    const [run, setRun] = React.useState(false);
    const [steps, setSteps] = React.useState(authType === "2" ? niftronSigninSecretDemo : niftronSigninPasswordDemo);

    const handleClose = () => {
        setOpen(false);
    };
    const switchToSecret = (e) => {
        e.preventDefault()
        helpers.skip()
        setRun(false)
        setSteps(niftronSigninSecretDemo)
        setAuthType("2")

    };
    const switchToPassword = (e) => {
        e.preventDefault()
        helpers.skip()
        setRun(false)
        setSteps(niftronSigninSecretDemo)
        setAuthType("0")
    };

    const getHelpers = (helpers) => {
        setHelpers(helpers);
    };

    const handleClickStart = (e) => {
        e.preventDefault();
        setRun(true)
    };

    const handleJoyrideCallback = (data) => {
        const { status } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setRun(false);
        }

        // tslint:disable:no-console
        // console.groupCollapsed(type);
        // console.log(data);
        // console.groupEnd();
        // tslint:enable:no-console
    };
    return (
        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>

                        <Joyride
                            callback={handleJoyrideCallback}
                            continuous={true}
                            getHelpers={getHelpers}
                            run={run}
                            scrollToFirstStep={true}
                            // showProgress={true}
                            showSkipButton={true}
                            steps={steps}
                            styles={{
                                options: {
                                    zIndex: 10000,
                                    backgroundColor: localStorage.getItem("theme") && localStorage.getItem("theme") === "LIGHT" ? "white" : "#282C34",
                                    arrowColor: localStorage.getItem("theme") && localStorage.getItem("theme") === "LIGHT" ? "white" : "#282C34"
                                },
                                buttonBack: { backgroundColor: "grey", color: "white" },
                                buttonNext: { backgroundColor: "green", color: "white" },
                                buttonSkip: { backgroundColor: "red", color: "white" },
                            }}
                        />
                        {authType !== "2" &&
                            <Formik
                                initialValues={{
                                    password: '',
                                    autosign: false
                                }}
                                validationSchema={
                                    Yup.object().shape({
                                        password: Yup.string().max(255).required('Password is required'),
                                    })
                                }
                                onSubmit={async (values) => {
                                    try {
                                        const secret = Niftron.utils.decryptSecret(encryptedSecret, sha256(values.password))
                                        if (!secret || secret === "" || secret === null || secret === undefined) {
                                            enqueueSnackbar("Invalid Credential", { variant: "warning" });
                                        } else if (publicKey !== Keypair.fromSecret(secret).publicKey()) {
                                            enqueueSnackbar("Credential Mismatch", { variant: "warning" });
                                        } else {
                                            enqueueSnackbar("Credential Validated Successfully", { variant: "success" });
                                            if (values.autosign) {
                                                localStorage.setItem("secretKey", secret)
                                            }
                                            onSecretKey(secret)
                                            setOpen(false)
                                        }
                                    } catch (e) {
                                        enqueueSnackbar(e.message, { variant: "error" });
                                        console.log(e.message)
                                    }
                                }}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values
                                }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box mb={3}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="h2"
                                                >
                                                    Approve action
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    gutterBottom
                                                    variant="body2"
                                                >
                                                    Provide your credentials
                                                          </Typography>
                                            </Box>
                                            <TextField
                                                id="password_demo"
                                                error={Boolean(touched.password && errors.password)}
                                                fullWidth
                                                helperText={touched.password && errors.password}
                                                label="Password"
                                                margin="normal"
                                                name="password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="password"
                                                value={values.password}
                                                variant="outlined"
                                            />
                                            {!hideAutoSign &&
                                                <>
                                                    <Box
                                                        alignItems="center"
                                                        display="flex"
                                                        ml={-1}
                                                    >
                                                        <Checkbox
                                                            id="autosign_demo"
                                                            checked={values.autosign}
                                                            name="autosign"
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                if (helpers !== undefined) {
                                                                    helpers.next()
                                                                }
                                                            }}
                                                        />
                                                        <Typography
                                                            color="textSecondary"
                                                            gutterBottom
                                                            variant="body2"
                                                        >
                                                            Autosign</Typography>
                                                    </Box>
                                                    {Boolean(touched.autosign && errors.autosign) && (
                                                        <FormHelperText error>
                                                            {errors.autosign}
                                                        </FormHelperText>
                                                    )}
                                                </>
                                            }
                                            <Box my={2}>

                                                <Button
                                                    id="proceedSign_demo"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                >
                                                    Proceed                    </Button>
                                            </Box>

                                            <Box my={2}>
                                                <Link id="switchToSecret_demo"><Typography
                                                    color="textSecondary"
                                                    gutterBottom
                                                    variant="body2"
                                                    onClick={switchToSecret}
                                                    component={Button}

                                                > Use Secret Key  </Typography>
                                                </Link>
                                                <Tooltip title="How?" placement="top">
                                                    <Fab style={{ float: "right", height: 20, width: 35 }} onClick={handleClickStart} color="primary" aria-label="help" className={classes.fab + " sizeSmall"}>
                                                        <HelpOutlineIcon />
                                                    </Fab>
                                                </Tooltip>
                                            </Box>
                                        </form>
                                    )}
                            </Formik>
                        }

                        {authType === "2" &&
                            <Formik
                                initialValues={{
                                    secretKey: '',
                                    autosign: false
                                }}
                                validationSchema={
                                    Yup.object().shape({
                                        secretKey: Yup.string().matches(/^[S,s][a-zA-Z0-9]{55}$/, "SecretKey format is invalid").required('SecretKey is required'),
                                    })
                                }
                                onSubmit={async (values) => {
                                    try {
                                        if (publicKey !== Keypair.fromSecret(values.secretKey).publicKey()) {
                                            enqueueSnackbar("Credential Mismatch", { variant: "warning" });
                                        } else {
                                            enqueueSnackbar("Credential Validated Successfully", { variant: "success" });
                                            if (values.autosign) {
                                                localStorage.setItem("secretKey", values.secretKey)
                                            }
                                            onSecretKey(values.secretKey)
                                            setOpen(false)
                                        }
                                    } catch (e) {
                                        enqueueSnackbar(e.message, { variant: "error" });
                                        console.log(e.message)
                                    }
                                }}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                    setFieldValue,
                                }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box mb={3}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="h2"
                                                >Approve action
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    gutterBottom
                                                    variant="body2"
                                                >
                                                    Provide your credentials
                                                          </Typography>

                                            </Box>
                                            <TextField
                                                id="secretKey_demo"
                                                error={Boolean(touched.secretKey && errors.secretKey)}
                                                fullWidth
                                                helperText={touched.secretKey && errors.secretKey}
                                                label="Secret Key"
                                                margin="normal"
                                                type="password"
                                                name="secretKey"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.secretKey}
                                                variant="outlined"
                                            />
                                            <Dropzone
                                                onDrop={acceptedFiles => {
                                                    const reader = new FileReader()
                                                    reader.addEventListener("load", async () => {
                                                        setFieldValue("secretKey", JSON.parse(atob(reader.result)).secretKey);
                                                        if (helpers !== undefined) {
                                                            helpers.next()
                                                        }

                                                    });
                                                    reader.readAsText(acceptedFiles[0])
                                                }}
                                                multiple={false} accept={[".niftron"]}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <Box mb={3} className={"container"}>
                                                        <div id="credentialFile_demo"{...getRootProps({ className: fileError ? 'dropzone2Error' : 'dropzone2' })}>
                                                            <input {...getInputProps()} />
                                                            <Typography
                                                                color="textPrimary"
                                                                variant="body2"
                                                            >Drop or select</Typography>
                                                            <Typography
                                                                color="textPrimary"
                                                                variant="body2"
                                                            >your niftron credential file</Typography>
                                                        </div>
                                                    </Box>
                                                )}
                                            </Dropzone>
                                            {!hideAutoSign &&
                                                <>
                                                    <Box
                                                        alignItems="center"
                                                        display="flex"
                                                        ml={-1}
                                                    >
                                                        <Checkbox
                                                            id="autosign_demo"
                                                            checked={values.autosign}
                                                            name="autosign"
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                if (helpers !== undefined) {
                                                                    helpers.next()
                                                                }
                                                            }}
                                                        />
                                                        <Typography
                                                            color="textSecondary"
                                                            gutterBottom
                                                            variant="body2"
                                                        >
                                                            Autosign</Typography>
                                                    </Box>
                                                    {Boolean(touched.autosign && errors.autosign) && (
                                                        <FormHelperText error>
                                                            {errors.autosign}
                                                        </FormHelperText>
                                                    )}
                                                </>
                                            }

                                            <Box my={2}>
                                                <Button
                                                    id="proceedSign_demo"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                >
                                                    Proceed                    </Button>
                                            </Box>
                                             <Box my={2}>
                                                {authTypeInput !== "2" &&<Link id="switchToPassword_demo"><Typography
                                                    color="textSecondary"
                                                    gutterBottom
                                                    variant="body2"
                                                    onClick={switchToPassword}
                                                    component={Button}

                                                > Use Password  </Typography>
                                                </Link>}
                                                <Tooltip title="How?" placement="top">
                                                    <Fab style={{ float: "right", height: 20, width: 35 }} onClick={handleClickStart} color="primary" aria-label="help" className={classes.fab + " sizeSmall"}>
                                                        <HelpOutlineIcon />
                                                    </Fab>
                                                </Tooltip>
                                            </Box>
                                        </form>
                                    )}
                            </Formik>
                        }

                    </div>
                </Fade>
            </Modal>
        </div >
    );
}
NiftronSignerModal.propTypes = {
    // children: PropTypes.node.isRequired,
    authTypeInput: PropTypes.string,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    onSecretKey: PropTypes.func,
    encryptedSecret: PropTypes.string,
    publicKey: PropTypes.string
};
export default NiftronSignerModal;
