import { NIFTRON } from "niftron-sdk";
const niftronConfig = {
    secretKey: process.env.REACT_APP_PROJECT_SECRET_KEY,
};

const niftron = new NIFTRON(niftronConfig);

niftron.initialize()

export default niftron;
