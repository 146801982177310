/* eslint-disable */
import React from 'react';
import {
  Card,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import DeveloperCredentials from './DeveloperCredentials';

import ProjectCredentials from './ProjectCredentials';
import { useSelector } from 'react-redux'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'src/components/TabPanel';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = (props) => {
  const classes = useStyles();
  const project = useSelector(state => state.ProjectKey)
  const projects = useSelector(state => state.Projects)

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page
      className={classes.root}
      title="Credentials"
    >
      <Container maxWidth="xl">
        <Card>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          // centered
          >
            <Tab label="Project Credentials" />
            <Tab label="Developer Credentials" />
          </Tabs>
        </Card>
        <TabPanel value={value} index={0}>
          {projects && projects.length > 0 && projects.map((item, i) => (
            <>
              {item.projectAccount.publicKey == project &&
                <ProjectCredentials history={props.history} project={item} key={i} />
              }
            </>
          ))}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {projects && projects.length > 0 && projects.map((item, i) => (
            <>
              {item.projectAccount.publicKey == project &&
                <DeveloperCredentials history={props.history} project={item}  key={i}/>
              }
            </>
          ))}
        </TabPanel>

        {/* <Preferences history={props.history} /> */}
        {/* <Box mt={3}>
          <Notifications />
        </Box>

        <Box mt={3}>
          <Password />
        </Box> */}
      </Container>
    </Page>
  );
};

export default SettingsView;
