// /* eslint-disable */
import React from "react";
import {
    Typography, Button
} from '@material-ui/core';
export const createProjectDemo = [
    {
        content: <Typography color="textPrimary" variant="h4">Let's begin our journey by creating a Niftron Project!</Typography>,
        // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
        placement: 'center',
        target: 'body',
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
    },
    {
        content: <Typography color="textPrimary" variant="h4">Give your project a name!</Typography>,
        //  < h2 > Give your project a name!</h2 >,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        spotlightClicks: true,
        // hideFooter: true,
        placement: 'top',
        target: '#name_demo',
    },
    {
        content: <Typography color="textPrimary" variant="h4">Be sure to check this!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        spotlightClicks: true,
        // hideFooter: true,
        placement: 'top',
        target: '#policy_demo',
        // title: 'Our Mission',
    },
    {
        content: <Typography color="textPrimary" variant="h4">Click to create your project!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        locale: { last: "End" },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        spotlightClicks: true,
        // hideFooter: true,
        placement: 'top',
        target: '#proceed_demo',
    }
]
export const niftronSigninSecretDemo = [
    {
        content: <Typography color="textPrimary" variant="h4">Just continue to the next step or Enter your niftron secret key,
        that is if you've got the memory of an elephant!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        // disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'bottom',
        target: '#secretKey_demo',
    },
    {
        content: <Typography color="textPrimary" variant="h4">Use your niftron credential file!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        // disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'top',
        target: '#credentialFile_demo',
    },
    {
        content: <Typography color="textPrimary" variant="h4">You can check this if you find entering your credentials frequently to be stressful!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        // disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'top',
        target: '#autosign_demo',
    },
    {
        content: <Typography color="textPrimary" variant="h4">Click to create your project!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        locale: { last: "End" },
        spotlightPadding: 10,
        disableBeacon: true,
        // disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'top',
        target: '#proceedSign_demo',
    },
    {
        content: <><Typography color="textPrimary" variant="h4">Want to give a shot with your password?</Typography>
            <Typography color="textSecondary" variant="body1">Try this!</Typography></>,
        floaterProps: {
            disableAnimation: true,
        },
        locale: { last: "End" },
        spotlightPadding: 10,
        disableBeacon: true,
        // disableOverlayClose: true,
        hideCloseButton: true,
        spotlightClicks: true,
        // hideFooter: true,
        placement: 'top',
        target: '#switchToPassword_demo',
    },
];
export const niftronSigninPasswordDemo = [
    {
        content: <Typography color="textPrimary" variant="h4">Enter your niftron password!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        // disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'top',
        target: '#password_demo',
    },
    {
        content: <Typography color="textPrimary" variant="h4">You can check this if you find entering your credentials frequently to be stressful!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        // disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'top',
        target: '#autosign_demo',
    },
    {
        content: <Typography color="textPrimary" variant="h4">Click to approve!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        locale: { last: "End" },
        spotlightPadding: 10,
        disableBeacon: true,
        // disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'top',
        target: '#proceedSign_demo',
    },
    {
        content: <><Typography color="textPrimary" variant="h4">Want to give a shot with your secret key?</Typography>
            <Typography color="textSecondary" variant="body1">Try this!</Typography></>,
        floaterProps: {
            disableAnimation: true,
        },
        locale: { last: "End" },
        spotlightPadding: 10,
        disableBeacon: true,
        // disableOverlayClose: true,
        hideCloseButton: true,
        spotlightClicks: true,
        // hideFooter: true,
        placement: 'top',
        target: '#switchToSecret_demo',
    },
];
export const LayoutIntroDemo = [
    {
        content:
            <Typography color="textPrimary" variant="h4">Let's have a look at the console layout!</Typography>,
        placement: 'center',
        locale: { last: "End" },
        target: 'body',
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
    },
    {
        content: <>
            <Typography color="textPrimary" variant="h4">Dashboard!</Typography>
            <Typography color="textSecondary" variant="body1">Click it to see the project overview.</Typography></>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        spotlightClicks: true,
        // hideFooter: true,
        placement: 'right',
        target: '#dashboard_nav_demo',
    },
    {
        content: <>
            <Typography color="textPrimary" variant="h4">Here's your Niftron Credits</Typography>
            <Typography color="textSecondary" variant="body1">This is the remainder of the free 50 niftrons credited after creating a project!</Typography></>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'bottom',
        target: '#dashboard_credits_demo',
    },
    {
        content:
            <Typography color="textPrimary" variant="h4">Here are the accounts created under this project!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'bottom',
        target: '#dashboard_accounts_demo',
    },
    {
        content:
            <Typography color="textPrimary" variant="h4">Here are the tokens created by the project!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'bottom',
        target: '#dashboard_tokens_demo',
    },
    {
        content:
            <Typography color="textPrimary" variant="h4">Here's your progress on creating a token!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'top',
        target: '#dashboard_progress_demo',
    },
    {
        content:
            <Typography color="textPrimary" variant="h4">The list of project contributors will appear here!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'top',
        target: '#dashboard_contributors_demo',
    },
    {
        content:
            <Typography color="textPrimary" variant="h4">You can find the Niftron Authentication Settings here!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'right',
        target: '#authentication_nav_demo',
    },
    {
        content:
            <Typography color="textPrimary" variant="h4">You can find the Niftron Tokenization Settings here!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'right',
        target: '#tokenization_nav_demo',
    },
    {
        content:
            <Typography color="textPrimary" variant="h4">You can find the Niftron Project Settings here!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'right',
        target: '#projectDetails_nav_demo',
    },
    {
        content:
            <Typography color="textPrimary" variant="h4">You can find the Project and Developer credentials here!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'right',
        target: '#credentials_nav_demo',
    },
    {
        content:
            <Typography color="textPrimary" variant="h4">Here's the niftron documentation!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'right',
        target: '#doc_nav_demo',
    },
    {
        content:
            <Typography color="textPrimary" variant="h4">You can redeem and recharge here!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'right',
        target: '#credits_nav_demo',
    },
    {
        content: <> <Typography color="textPrimary" variant="h4">Not a vampire?</Typography>
            <Typography color="textPrimary" variant="h4">No worries!</Typography>
            <Typography color="textSecondary" variant="body1">You've got Light Mode in the preferences!</Typography>
        </>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'top-right',
        target: '#settings_nav_demo',
    },

    {
        content:
            <Typography color="textPrimary" variant="h4">Feel like taking a break? End your current session here!</Typography>,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 10,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        // spotlightClicks: true,
        // hideFooter: true,
        placement: 'top-right',
        target: '#logout_nav_demo',
        locale: { last: "End" },

    },
]
export const GettingStartedDemo = [
    {
        content:
            <>
                <Typography color="textPrimary" variant="h4">Let's Get Started!</Typography>
                <Typography color="textSecondary" variant="body1">
                    <Button color="primary"
                        //    size="large"
                        target="_blank" rel="noopener noreferrer" href="https://tutorials.niftron.com/">
                        Click Here </Button> to checkout our environment setup tutorial.
                 </Typography>
            </>,
        placement: 'center',
        target: 'body',
        disableBeacon: true,
        // disableOverlayClose: true,
        hideCloseButton: true,
        locale: { last: "End" },


    }
]

export const TokenizationDemo = [
    {
        content:
            <>
                <Typography color="textPrimary" variant="h4">Let's tokenize with niftron!</Typography>
                <Typography color="textSecondary" variant="body1">
                    <Button color="primary"
                        //    size="large"
                        target="_blank" rel="noopener noreferrer" href="https://tutorials.niftron.com/how-to-create-a-digital-badge">
                        Click Here </Button> to checkout our tokenization tutorial.
                 </Typography>
            </>,
        placement: 'center',
        target: 'body',
        disableBeacon: true,
        // disableOverlayClose: true,
        hideCloseButton: true,
        locale: { last: "End" },

    }
]
export const TransactionDemo = [
    {
        content:
            <>
                <Typography color="textPrimary" variant="h4">Let's transact with niftron!</Typography>
                <Typography color="textSecondary" variant="body1">
                    <Button color="primary"
                        //    size="large"
                        target="_blank" rel="noopener noreferrer" href="https://tutorials.niftron.com/how-to-transfer-a-digital-badge">
                        Click Here </Button> to checkout our transactions tutorial.
                 </Typography>
            </>,
        placement: 'center',
        target: 'body',
        disableBeacon: true,
        // disableOverlayClose: true,
        hideCloseButton: true,
        locale: { last: "End" },

    }
]
export const AuthenticationDemo = [
    {
        content:
            <>
                <Typography color="textPrimary" variant="h4">Let's authenticate with niftron!</Typography>
                <Typography color="textSecondary" variant="body1">
                    <Button color="primary"
                        //    size="large"
                        target="_blank" rel="noopener noreferrer" href="https://tutorials.niftron.com/how-to-authenticate">
                        Click Here </Button> to checkout our authentication tutorial.
                 </Typography>
            </>,
        placement: 'center',
        target: 'body',
        disableBeacon: true,
        // disableOverlayClose: true,
        hideCloseButton: true,
        locale: { last: "End" },

    }
];
//
// export const TokenizationDemo = [
//     {
//         content: <h4>Let's tokenize with niftron!</h4>,
//         // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
//         placement: 'center',
//         target: 'body',
//         disableBeacon: true,
//         disableOverlayClose: true,
//         hideCloseButton: true,
//     },
//     {
//         content: <><h4>Dashboard!</h4><h4>Click it to see the project overview.</h4></>,
//         floaterProps: {
//             disableAnimation: true,
//         },
//         spotlightPadding: 10,
//         // disableBeacon: true,
//         // disableOverlayClose: true,
//         // hideCloseButton: true,
//         spotlightClicks: true,
//         // hideFooter: true,
//         placement: 'right',
//         target: '#dashboard_nav_demo',
//     },
//     {
//         content: <><h4>Here's your Niftron Credits</h4><h4>This is the remainder of the free 100 niftrons credited after creating a project</h4>!</>,
//         floaterProps: {
//             disableAnimation: true,
//         },
//         spotlightPadding: 10,
//         disableBeacon: true,
//         // disableOverlayClose: true,
//         // hideCloseButton: true,
//         // spotlightClicks: true,
//         // hideFooter: true,
//         placement: 'bottom',
//         target: '#dashboard_credits_demo',
//     },
//     {
//         content: <h4>Here are the accounts created under this project!</h4>,
//         floaterProps: {
//             disableAnimation: true,
//         },
//         spotlightPadding: 10,
//         disableBeacon: true,
//         disableOverlayClose: true,
//         hideCloseButton: true,
//         // spotlightClicks: true,
//         // hideFooter: true,
//         placement: 'bottom',
//         target: '#dashboard_accounts_demo',
//     },
//     {
//         content: <h4>Here are the tokens created by the project!</h4>,
//         floaterProps: {
//             disableAnimation: true,
//         },
//         spotlightPadding: 10,
//         disableBeacon: true,
//         disableOverlayClose: true,
//         hideCloseButton: true,
//         // spotlightClicks: true,
//         // hideFooter: true,
//         placement: 'bottom',
//         target: '#dashboard_tokens_demo',
//     },
//     {
//         content: <h4>Here's your progress on creating a token!</h4>,
//         floaterProps: {
//             disableAnimation: true,
//         },
//         spotlightPadding: 10,
//         disableBeacon: true,
//         disableOverlayClose: true,
//         hideCloseButton: true,
//         // spotlightClicks: true,
//         // hideFooter: true,
//         placement: 'top',
//         target: '#dashboard_progress_demo',
//     },
//     {
//         content: <h4>The list of project contributors will appear here!</h4>,
//         floaterProps: {
//             disableAnimation: true,
//         },
//         spotlightPadding: 10,
//         disableBeacon: true,
//         disableOverlayClose: true,
//         hideCloseButton: true,
//         // spotlightClicks: true,
//         // hideFooter: true,
//         placement: 'top',
//         target: '#dashboard_contributors_demo',
//     },
//     {
//         content: <h4>You can find the Niftron Authentication Settings here!</h4>,
//         floaterProps: {
//             disableAnimation: true,
//         },
//         spotlightPadding: 10,
//         disableBeacon: true,
//         disableOverlayClose: true,
//         hideCloseButton: true,
//         // spotlightClicks: true,
//         // hideFooter: true,
//         placement: 'right',
//         target: '#authentication_nav_demo',
//     },
//     {
//         content: <h4>You can find the Project Credentials here!</h4>,
//         floaterProps: {
//             disableAnimation: true,
//         },
//         spotlightPadding: 10,
//         disableBeacon: true,
//         disableOverlayClose: true,
//         hideCloseButton: true,
//         // spotlightClicks: true,
//         // hideFooter: true,
//         placement: 'right',
//         target: '#credentials_nav_demo',
//     },

//     {
//         content: <> <h4>Are you a vampire?</h4><h4>No worries!</h4>
//             <h4>You've got Dark Mode in the preferences!</h4>
//         </>,
//         floaterProps: {
//             disableAnimation: true,
//         },
//         spotlightPadding: 10,
//         disableBeacon: true,
//         disableOverlayClose: true,
//         hideCloseButton: true,
//         // spotlightClicks: true,
//         // hideFooter: true,
//         placement: 'top-right',
//         target: '#settings_nav_demo',
//     },
//     {
//         content: <h4>Feel like taking a break? End your current session here!</h4>,
//         floaterProps: {
//             disableAnimation: true,
//         },
//         spotlightPadding: 10,
//         disableBeacon: true,
//         disableOverlayClose: true,
//         hideCloseButton: true,
//         // spotlightClicks: true,
//         // hideFooter: true,
//         placement: 'top-right',
//         target: '#logout_nav_demo',
//     },
// ]