// /* eslint-disable */
import React from "react";
import Niftron from "src/baas/config";
// import { Navigate } from 'react-router-dom';

const withAuthorization = authCondition => Component => {
    const WithAuthorization = props => {
        const [render, setRender] = React.useState(null)
        React.useEffect(() => {
            Niftron.user.onAuthStateChanged(
                authUser => {
                    // console.log(authUser)
                    if (!authCondition(authUser)) {
                        localStorage.removeItem("secretKey")
                        Niftron.user.authRedirect({
                            redirectUrl: window.location.href,
                            warning: "Login with a Developer Account",
                            userType: "0"
                        })
                        // setRender(<Navigate to="/auth" />)
                    } else {
                        setRender(<Component {...props} />)
                    }
                },
                err => {
                    if (err) {
                        // console.log(err)
                        localStorage.removeItem("secretKey")
                        Niftron.user.authRedirect({
                            redirectUrl: window.location.href,
                            warning: "Login with a Developer Account",
                            userType: "0"
                        })
                        // setRender(<Navigate to="/auth" />)
                    }
                }
            );
        }, [props]);
        return render;
    }
    return WithAuthorization;
};
export default withAuthorization;
