/* eslint-disable */
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  makeStyles,
  Box,
  List,
  ListItem, Button, Divider, Typography
} from '@material-ui/core';
import clsx from 'clsx';
import NavBar from './NavBar';
import TopBar from './TopBar';
import withAuthorization from 'src/components/Authetication';
// import TasksProgress from './TasksProgress';
import Niftron from "src/baas/config";
import { getProjectByContributor } from "src/services/projectManagement";
// import ProjectListView from 'src/views/createProject/ProjectListView';
// import CreateProjectView from 'src/views/createProject/CreateProjectView';
import { useSelector } from 'react-redux'
import store from "src/redux/reducers"
import { ADD_PROJECTS, ADD_PROJECT_KEY, ADD_HELPERS, ADD_DEMO_STEPS } from 'src/redux/constants/ActionTypes';
import Joyride, { STATUS } from 'react-joyride';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {
  // createProjectDemo,
  LayoutIntroDemo,
  GettingStartedDemo,
  AuthenticationDemo,
  TokenizationDemo,
  TransactionDemo
} from "src/demos/Demo"
import { useNavigate } from "react-router"

const useStyles = makeStyles((theme) => ({
  paperColor: theme.palette.background.paper,
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 10000
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  wrapper2: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: 256
    // }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
}));

const ConsoleLayout = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const projects = useSelector(state => state.Projects)
  // const loading = useSelector(state => state.Loading)

  // const [showDemo, setShowDemo] = React.useState(false);
  const [helpers, setHelpers] = React.useState(null);
  const [run, setRun] = React.useState(false)
  //

  const steps = useSelector(state => state.DemoSteps)

  const getHelpers = (helpers) => {
    setHelpers(helpers);
    store.dispatch({ type: ADD_HELPERS, text: helpers })
  };

  const handleClickStart = (e) => {
    e.preventDefault();
    setRun(true)
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRun(false);
      store.dispatch({ type: ADD_HELPERS, text: null })
    }
    // tslint:disable:no-console
    // console.groupCollapsed(type);
    // console.log(data);
    // console.groupEnd();
    // tslint:enable:no-console
  };

  React.useEffect(() => {
    Niftron.user.onAuthStateChanged(
      authUser => {
        setAuthUser(authUser)

      },
      err => {
        if (err) {
          console.log(err)
        }
      }
    );

  }, []);

  React.useEffect(() => {
    if (projects.length > 1) {
      setRun(false)
    }
  }, [projects, navigate]);

  React.useEffect(() => {
    if (helpers) {
      helpers.skip()
    }
    store.dispatch({ type: ADD_DEMO_STEPS, text: LayoutIntroDemo })

  }, [helpers]);

  React.useEffect(() => {
    const fetchProjects = async () => {
      try {
        if (authUser) {
          const projectsRes = await getProjectByContributor(authUser.publicKey)
          // setProjects(projectsRes.data)
          if (projectsRes.data.length > 0) {
            store.dispatch({ type: ADD_PROJECT_KEY, text: projectsRes.data[0].projectAccount.publicKey })
            store.dispatch({ type: ADD_PROJECTS, text: projectsRes.data })

            // if (localStorage.getItem("layoutDone")) {
            //   setRun(false)
            // } else {
            //   setRun(true)
            //   localStorage.setItem("layoutDone", true)
            // }
            if (localStorage.getItem("layoutDone")) {
              if (localStorage.getItem("layoutDone") != "DONE") {
                setRun(true)
                localStorage.setItem("layoutDone", "DONE")
              }
            } else {
              setRun(true)
              localStorage.setItem("layoutDone", "DONE")
            }


            // if (projectsRes.data[0].isOnboarding) {
            //   setRun(true)
            // }
            // if (projectsRes.data[0].isOnboarding && projectsRes.data[0].onBoardingLevel) {
            //   switch (projectsRes.data[0].onBoardingLevel) {
            //     case 0: store.dispatch({ type: ADD_DEMO_STEPS, text: LayoutIntroDemo }); break;
            //     case 5: store.dispatch({ type: ADD_DEMO_STEPS, text: GettingStartedDemo }); break;
            //     case 10: store.dispatch({ type: ADD_DEMO_STEPS, text: TokenizationDemo }); break;
            //     case 20: store.dispatch({ type: ADD_DEMO_STEPS, text: TransactionDemo }); break;
            //     case 40: store.dispatch({ type: ADD_DEMO_STEPS, text: AuthenticationDemo }); break;
            //     default: store.dispatch({ type: ADD_DEMO_STEPS, text: LayoutIntroDemo })
            //   }
            // }
          }
          else {
            navigate("/project/create", { replace: true });

          }
        }
      } catch (err) {

      }
    }
    fetchProjects()
  }, [authUser, navigate]);
  const closeDrawer = () => {
    setOpen(false)
  }
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} projects={projects} />
      {projects && projects.length > 0 &&
        <><Tooltip title="How?" placement="top">
          <Fab
            onClick={() => {
              if (helpers) {
                helpers.skip()
              }
              setOpen(!open)
            }}
            color="primary" aria-label="help" className={classes.fab}>
            <HelpOutlineIcon />
          </Fab>
        </Tooltip>

          <Drawer anchor={"right"} open={open} onClose={closeDrawer}>
            <Box p={2} >
              <List>
                <ListItem key={"Tutorials"}
                  className={clsx(classes.item)}
                  disableGutters
                >
                  <Typography
                    color="textPrimary"
                    variant="h5"
                  >
                    Tutorials
              </Typography>
                </ListItem>
                <Divider />
                <ListItem key={"Layout"}
                  className={clsx(classes.item)}
                  disableGutters
                >
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    onClick={(e) => {
                      closeDrawer()
                      store.dispatch({ type: ADD_DEMO_STEPS, text: LayoutIntroDemo })
                      handleClickStart(e)
                    }}
                  >
                    <span className={classes.title}>Layout</span>
                  </Button>
                </ListItem>
                <ListItem key={"Getting Started"}
                  className={clsx(classes.item)}
                  disableGutters
                >
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    onClick={(e) => {
                      closeDrawer()
                      store.dispatch({ type: ADD_DEMO_STEPS, text: GettingStartedDemo })
                      handleClickStart(e)
                    }}
                  >
                    <span className={classes.title}>Getting Started</span>
                  </Button>
                </ListItem>
                <ListItem key={"Authentication"}
                  className={clsx(classes.item)}
                  disableGutters
                >
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    onClick={(e) => {
                      closeDrawer()
                      store.dispatch({ type: ADD_DEMO_STEPS, text: TokenizationDemo })
                      handleClickStart(e)
                    }}
                  >
                    <span className={classes.title}>Tokenization</span>
                  </Button>
                </ListItem>
                <ListItem key={"Tokenization"}
                  className={clsx(classes.item)}
                  disableGutters
                >
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    onClick={(e) => {
                      closeDrawer()
                      store.dispatch({ type: ADD_DEMO_STEPS, text: TransactionDemo })
                      handleClickStart(e)
                    }}
                  >
                    <span className={classes.title}>Transactions</span>
                  </Button>
                </ListItem>
              </List>
            </Box>
          </Drawer>
          <Joyride
            callback={handleJoyrideCallback}
            continuous={true}
            getHelpers={getHelpers}
            run={run}
            scrollToFirstStep={true}
            // showProgress={true}
            showSkipButton={true}
            steps={steps}
            styles={{
              options: {
                zIndex: 10000,
                backgroundColor: localStorage.getItem("theme") && localStorage.getItem("theme") === "LIGHT" ? "white" : "#282C34",
                arrowColor: localStorage.getItem("theme") && localStorage.getItem("theme") === "LIGHT" ? "white" : "#282C34"
              },
              buttonBack: { backgroundColor: "grey", color: "white" },
              buttonNext: { backgroundColor: "green", color: "white" },
              buttonSkip: { backgroundColor: "red", color: "white" },
            }}
          />
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen} projects={projects}
          />
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>
                <Outlet />
              </div>
            </div>
          </div>
        </>
      }
      {/* {projects.length === 0 && !loading &&
        <div className={classes.wrapper2}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <CreateProjectView new={true} />
            </div>
          </div>
        </div>
      } */}
      {/* {projects.length == 0 && loading &&
        <div className={classes.wrapper2}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <ProjectListView />
            </div>
          </div>
        </div>
      } */}
    </div>
  );
};
const authCondition = authUser => !!authUser && authUser.type === "0";
export default withAuthorization(authCondition)(ConsoleLayout);
// export default DashboardLayout;
