/* eslint-disable */
import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Card,
  //  Box,
  CardHeader
} from '@material-ui/core';
import Page from 'src/components/Page';
import CreditTest from './CreditTest';
import LatestOrders from './LatestOrders';
// import LatestProducts from './LatestProducts';
// import Sales from './Sales';
import TasksProgress from './TasksProgress';
import TotalAccounts from './TotalAccounts';
import TokensCreated from './TokensCreated';
// import TrafficByDevice from './TrafficByDevice';
import { useSelector } from 'react-redux'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'src/components/TabPanel';
import { useSnackbar } from 'notistack';
import MaintenanceBanner from "src/components/MaintenanceBanner"
import { Networks } from "stellar-sdk";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const project = useSelector(state => state.ProjectKey)
  const projects = useSelector(state => state.Projects)
  const [value, setValue] = React.useState(0);
  // const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth="xl">

        <Card>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          // centered
          >
            <Tab label="Sandbox" />
            <Tab label="Live" disabled />
          </Tabs>
        </Card>
        <TabPanel value={value} index={0}><br />
        <MaintenanceBanner currentNetwork={"test"} /><br />
          {projects && projects.length > 0 && projects.map((item, i) => (
            <Container maxWidth={false} key={i} >
              {item.projectAccount.publicKey == project &&
                <Grid
                  container
                  spacing={3}
                >

                  <Grid
                    item
                    lg={4}
                    sm={6}
                    xl={3}
                    xs={12}
                  >
                    <CreditTest project={item} id="dashboard_credits_demo" />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    sm={6}
                    xl={3}
                    xs={12}
                  >
                    <TotalAccounts project={item} id="dashboard_accounts_demo" />
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    sm={6}
                    xl={3}
                    xs={12}
                  >
                    <TokensCreated project={item} id="dashboard_tokens_demo" />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    sm={6}
                    xl={3}
                    xs={12}
                  >
                    <TasksProgress project={item} id="dashboard_progress_demo" />
                  </Grid>
                  {/* <Grid
                      item
                      lg={8}
                      md={12}
                      xl={9}
                      xs={12}
                    >
                      <Sales project={item} />
                    </Grid> */}
                  {/* <Grid
                      item
                      lg={4}
                      md={6}
                      xl={3}
                      xs={12}
                    >
                      <TrafficByDevice project={item} />
                    </Grid> */}
                  {/* <Grid
                      item
                      lg={4}
                      md={6}
                      xl={3}
                      xs={12}
                    >
                      <LatestProducts project={item} />
                    </Grid> */}
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xl={12}
                    xs={12}
                  >
                    <LatestOrders project={item} id="dashboard_contributors_demo" />
                  </Grid>
                </Grid>

              }
            </Container>
          ))}
        </TabPanel>
      </Container>


    </Page>
  );
};

export default Dashboard;
