/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Container,

  Typography,
  makeStyles
} from '@material-ui/core';
import { useNavigate, useLocation } from "react-router"
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { editProject } from "src/services/projectManagement"
const signInMethodsList = [
  {
    value: 'GLOBAL',
    label: 'Niftron Global Sign-In'
  },
  {
    value: 'PROJECT',
    label: 'Project Specific Sign-In'
  },
];
const userTypeList = [
  {
    value: '0',
    label: 'Niftron Creator Account'
  },
  {
    value: '1',
    label: 'Niftron Collector Account'
  },
];

const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Recharge = ({ className, ...rest }) => {
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [darkMode, setDarkMode] = React.useState(localStorage.getItem("theme") &&
    localStorage.getItem("theme") === "DARK" ? true : false)

  return (

    <Card>
      <CardContent>
        <Formik
          initialValues={{
            amount: 0,
            signInMethod: 'GLOBAL',
            warningMessage: '',
            userAccountType: '0',
          }}
          validationSchema={
            Yup.object().shape({
              redirectURL: Yup.string().max(255).required('Redirect URL is required'),
              signInMethod: Yup.string().max(255).required('Sign-In Method is required'),
              warningMessage: Yup.string().max(255).optional(),
              userAccountType: Yup.string().max(255).required('User Account Type is required'),
            })
          }
          onSubmit={async (values) => {
            try {

              // await editProject(
              //   projectData.projectAccount.publicKey,
              //   {
              //     authentication: {
              //       signInMethod: values.signInMethod,
              //       redirectURL: values.redirectURL,
              //       warningMessage: values.warningMessage,
              //       userAccountType: values.userAccountType
              //     }
              //   }
              // )
              enqueueSnackbar("Project Updated", { variant: "success" });

            } catch (e) {
              enqueueSnackbar(e.message, { variant: "error" });
              console.log(e.message)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={5}>
                  <Typography
                    color="textPrimary"
                    variant="h3"
                  >
                    Recharge Now
                    </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Running low on Niftron credits? Purchase more here!
                    </Typography><Divider />
                </Box>


                <Box mb={3}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    The following setting let's you decide how your users will interract with your blockchain project.
                    </Typography>
                </Box>

                <Box mb={3}>

                  <TextField
                    fullWidth
                    label="Select A Suitable Sign-In Method"
                    name="signInMethod"
                    onChange={handleChange}
                    // required
                    select
                    SelectProps={{ native: true }}
                    value={values.signInMethod}
                    variant="outlined"
                  >
                    {signInMethodsList.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Box>
                <Box mb={3}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    The following setting let's you decide the type of niftron
                    account your users will use and hences deciding the fuctionalities that your offer them.
                    </Typography>
                </Box>
                <Box my={2}>

                  <TextField
                    fullWidth
                    label="Select Auth Account Type"
                    name="userAccountType"
                    onChange={handleChange}
                    // required
                    select
                    SelectProps={{ native: true }}
                    value={values.userAccountType}
                    variant="outlined"
                  >
                    {userTypeList.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Box>
                <Box mb={3}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    The following setting let's you assign a return url that the project is deployed at.
                    </Typography>
                  <TextField
                    error={Boolean(touched.redirectURL && errors.redirectURL)}
                    fullWidth
                    helperText={touched.redirectURL && errors.redirectURL}
                    label="Redirect URL"
                    margin="normal"
                    name="redirectURL"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // type="text"
                    value={values.redirectURL}
                    variant="outlined"
                  />
                </Box>

                <Box my={3}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    The following setting let's you provide the users with a warning message if necessary.
                    </Typography>
                  <TextField
                    error={Boolean(touched.warningMessage && errors.warningMessage)}
                    fullWidth
                    helperText={touched.warningMessage && errors.warningMessage}
                    label="Message to User"
                    margin="normal"
                    name="warningMessage"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // type="text"
                    value={values.warningMessage}
                    variant="outlined"
                  />
                </Box>

                <Box my={2}>
                  <Container maxWidth="sm">  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                    </Button>  </Container>
                </Box>
              </form>
            )}
        </Formik>

      </CardContent>


      <Divider />
    </Card>

  );
};

Recharge.propTypes = {
  className: PropTypes.string
};

export default Recharge;
