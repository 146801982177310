// /* eslint-disable */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { createProject } from "src/services/projectManagement";
import { useNavigate } from "react-router"
import NiftronSigner from "src/components/NiftronSigner"
import MessagePopUp from "src/components/MessagePopUp"
import Niftron from "src/baas/config";
import { Keypair } from 'stellar-sdk';
import Joyride, { STATUS } from 'react-joyride';
// import './styles.css';
import Tooltip from '@material-ui/core/Tooltip';

import Fab from '@material-ui/core/Fab';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useSelector } from 'react-redux'
import { createProjectDemo } from "src/demos/Demo.js"
const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}));

const message = <>
  <Typography
    color="textPrimary"
    variant="h2"
  >Terms and Conditions</Typography>
  <br/>
  <Typography
    color="textSecondary"
    // gutterBottom
    variant="body1"
  >
    All Niftron projects are created as a testnet project until activated to live net.
  </Typography>

  <Typography
    color="textSecondary"
    // gutterBottom
    variant="body1"
  >
    0.52 Niftrons is reserved for every project created and will be returned to the developer upon deleting the project.
  </Typography>

  <Typography
    color="textSecondary"
    // gutterBottom
    variant="body1"
  >
    This shouldn't worry you as every developer is given 50 testnet Niftrons upon registration.
  </Typography>
</>;
const Registration = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [helpers, setHelpers] = React.useState(null);
  const projects = useSelector(state => state.Projects)

  const [run, setRun] = React.useState(true);
  const [steps] = React.useState(createProjectDemo);


  const [open, setOpen] = React.useState(false);
  const [messageOpen, setMessageOpen] = React.useState(false);

  const [authUser, setAuthUser] = React.useState(null);
  // const [secretKey, setSecretKey] = React.useState("");
  const [projectData, setProjectData] = React.useState(null);

  React.useEffect(() => {
    if (projects.length > 0) {
      setRun(false)
    }
  }, [projects, navigate]);

  React.useEffect(() => {
    Niftron.user.onAuthStateChanged(
      authUser => {
        setAuthUser(authUser)
      },
      err => {
        if (err) {
          console.log(err)
        }
      }
    );
  }, []);

  const getHelpers = (helpers) => {
    setHelpers(helpers);
  };

  const handleClickStart = (e) => {
    e.preventDefault();
    setRun(true)
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }

    // tslint:disable:no-console
    // console.groupCollapsed(type);
    // console.log(data);
    // console.groupEnd();
    // tslint:enable:no-console
  };


  // const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // const loadingMessage = useSelector(state => state.LoadingMessage)

  const approveSubmit = async (secretKey, values) => {
    try {
      helpers.skip()
      // console.log(authUser)
      if (projects.length > 0) {
        await createProject(values ? values : projectData, Keypair.fromSecret(secretKey), authUser, false, 0)
      } else {
        await createProject(values ? values : projectData, Keypair.fromSecret(secretKey), authUser, true, 0)
      }

      // await createProject(values ? values : projectData, Keypair.fromSecret(secretKey), authUser, true, 0)
      enqueueSnackbar("Project Created Successfully", { variant: "success" });
      navigate("/", { replace: true });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
      console.log(e.message)
    }
  }

  return (

    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    ><Tooltip title="How?" placement="top">
        <Fab onClick={handleClickStart} color="primary" aria-label="help" className={classes.fab}>
          <HelpOutlineIcon />
        </Fab>
      </Tooltip>
      {authUser &&
        <Joyride
          callback={handleJoyrideCallback}
          continuous={true}
          getHelpers={getHelpers}
          run={run}
          scrollToFirstStep={true}
          // showProgress={true}
          showSkipButton={true}
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
              backgroundColor: localStorage.getItem("theme") && localStorage.getItem("theme") === "LIGHT" ? "white" : "#282C34",
              arrowColor: localStorage.getItem("theme") && localStorage.getItem("theme") === "LIGHT" ? "white" : "#282C34"
            },
            buttonBack: { backgroundColor: "grey", color: "white" },
            buttonNext: { backgroundColor: "green", color: "white" },
            buttonSkip: { backgroundColor: "red", color: "white" },
          }}
        />
      }

      <Container maxWidth="sm">
        <MessagePopUp message={message} open={messageOpen} setOpen={setMessageOpen} />
        {
          authUser && <NiftronSigner open={open} setOpen={setOpen} authTypeInput={authUser.authType} publicKey={authUser.publicKey}
            encryptedSecret={authUser.encryptedSecret} onSecretKey={approveSubmit} helpers={helpers} showDemo={run} />
          // authUser && <NiftronSigner open={open} setOpen={setOpen} authType={"0"}
          // encryptedSecret={authUser.encryptedSecret} onSecretKey={approveSubmit} />
        }
        <Formik
          initialValues={{
            name: '',
            // usecase: 'BANKING',
            policy: false
          }}
          validationSchema={
            Yup.object().shape({
              name: Yup.string().max(255).required('Project name is required'),
              // usecase: Yup.string().max(255).required('usecase is required'),
              policy: Yup.boolean().oneOf([true], 'This field must be checked')
            })
          }
          onSubmit={async (values) => {
            try {
              setProjectData(values)
              if (localStorage.getItem("secretKey")) {
                if (authUser.publicKey === Keypair.fromSecret(localStorage.getItem("secretKey")).publicKey()) {
                  approveSubmit(localStorage.getItem("secretKey"), values)
                  return
                }
              } else {

                const info = helpers.info()
                // helpers.skip()
                // helpers.reset(true)

                setOpen(true)
                helpers.next()
                // helpers.open()
                // setTimeout(() => {
                //   // helpers.reset(true)
                //   helpers.go(info.index + 1)

                // }, 300)

                return
              }


              // enqueueSnackbar("Blockchain account created successfully", {variant: "success" });
              // navigate("/", {replace: true });
            } catch (e) {
              enqueueSnackbar(e.message, { variant: "error" });
              console.log(e.message)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Create a new project
                    </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    You can change the name of your project later
                    </Typography>
                </Box>
                {/* <TextField
                  fullWidth
                  label="Select A Relatable Usecase"
                  name="usecase"
                  id="usecase_demo"
                  onChange={(e) => {
                    handleChange(e)
                    if (e.target.value == "OTHER") {
                      setOtherUsecase(true)
                    } else {
                      setOtherUsecase(false)
                    }
                    setTimeout(() => {
                      helpers.next()

                    }, 100)
                  }}
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                >
                  {states.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
                {otherUsecase &&
                  <TextField
                    id="other_demo"
                    error={Boolean(touched.usecase && errors.usecase)}
                    fullWidth
                    helperText={touched.usecase && errors.usecase}
                    label="Mention Other Usecases"
                    margin="normal"
                    name="usecase"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                } */}

                <TextField
                  id="name_demo"
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Project Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  // type="text"
                  value={values.name}
                  variant="outlined"
                />
                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    id="policy_demo"
                    checked={values.policy}
                    name="policy"
                    onChange={(e) => {
                      handleChange(e)
                      helpers.next()

                    }}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    I have read the
                      {' '}
                    <Button
                      color="primary"
                      onClick={() => {
                        setMessageOpen(true)
                      }}
                    // underline="always"
                    // variant="h6"
                    >
                      Terms and Conditions
                      </Button>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  <Button
                    id="proceed_demo"

                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Proceed
                    </Button>
                </Box>
                {/* <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                    {' '} */}
                {projects.length > 0 && <Link
                  component={RouterLink}
                  to="/dashboard"
                  variant="h6"
                >
                  Go Back
                    </Link>}

                {/* </Typography> */}
              </form>
            )}
        </Formik>
      </Container>
    </Box >

  );
};

export default Registration;
