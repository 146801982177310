/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Container,

  Typography,
  makeStyles
} from '@material-ui/core';
import { useNavigate, useLocation } from "react-router"
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { editProject } from "src/services/projectManagement"
import ImageGetCrop from "src/components/ImageCropper/ImgDialog.js";
import Profile from './Profile';

const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const ProjectDetails = ({ className, projectData, ...rest }) => {
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [darkMode, setDarkMode] = React.useState(localStorage.getItem("theme") &&
    localStorage.getItem("theme") === "DARK" ? true : false)

  // React.useEffect(() => {

  // }, []);
  return (

    <Card>{
      projectData &&
      <CardContent>
        <Formik
          initialValues={{
            name: projectData.name ? projectData.name : '',
          }}
          validationSchema={
            Yup.object().shape({
              name: Yup.string().max(255).required('Project Name is required'),
            })
          }
          onSubmit={async (values) => {
            try {

              await editProject(
                projectData.projectAccount.publicKey,
                {
                  name: values.name
                }
              )
              enqueueSnackbar("Project Updated", { variant: "success" });

            } catch (e) {
              enqueueSnackbar(e.message, { variant: "error" });
              console.log(e.message)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={5}>
                  <Typography
                    color="textPrimary"
                    variant="h3"
                  >
                    Project Details
                    </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Modify your project meta data here.</Typography><Divider />
                </Box>
                <Profile imgSrc={projectData.projectLogoURL} project={projectData}/>
                {/* <Box mb={3}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Your Project needs a logo to appear more presentable in the niftron services.
                    </Typography>
                 
                </Box> */}
                <Box mb={3}>
                  {/* <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Your Project Name, the ball is in your court with this one.
                    </Typography> */}
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Project Name"
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // type="text"
                    value={values.name}
                    variant="outlined"
                  />
                </Box>

                <Box my={2}>
                  <Container maxWidth="sm">  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                    </Button>  </Container>
                </Box>
              </form>
            )}
        </Formik>

      </CardContent>


    }
      <Divider />
    </Card >

  );
};

ProjectDetails.propTypes = {
  className: PropTypes.string
};

export default ProjectDetails;
