/* eslint-disable */
import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles,
  TextField
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useSnackbar } from 'notistack';
import InputAdornment from '@material-ui/core/InputAdornment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestOrders = ({ className, project, ...rest }) => {
  const classes = useStyles();
  const [contributors, setContributors] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const copyMessage = (val) => {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }
  const givePrivacy = (authType) => {
    switch (authType) {
      case "0": return "HIGH";
      case "1": return "MEDIUM";
      case "2": return "LOW";
      default: return null;
    }
  }

  React.useEffect(() => {
    const fetchBalance = async () => {
      if (project.contributors && project.contributors.length > 0) {
        setContributors(project.contributors)
      }
    }
    fetchBalance()
  }, []);
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Project Contributors" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={300}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell>
                  Alias
                </TableCell> */}
                <TableCell>
                  Public Key
                </TableCell>
                <TableCell>
                  Role
                </TableCell>
                <TableCell>
                  Privacy
                </TableCell>
                {/* <TableCell>
                  Credits
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {contributors.length > 0 && contributors.map((contributors, i) => (
                <TableRow
                  hover
                  key={i}
                >
                  <TableCell>
                    <TextField
                      // fullWidth
                      name="publicKey"
                      value={contributors.publicKey}
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <Button onClick={e => {
                            e.preventDefault()
                            copyMessage(contributors.publicKey)
                            enqueueSnackbar("Public Key is copied to clipboard", { variant: "info" });
                          }}> <FileCopyIcon /> </Button></InputAdornment>,
                      }}
                    />
                  </TableCell>

                  <TableCell>
                    <Chip
                      color="primary"
                      label={contributors.accountType == 0 ? "CREATOR" : "CONTRIBUTOR"}
                      // size="large"
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      color="secondary"
                      label={givePrivacy(contributors.authType)}
                      // size="large"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
      </Box>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string
};

export default LatestOrders;
