/* eslint-disable */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  TextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useSnackbar } from 'notistack';
import CryptingText from "src/components/CryptingText.js"
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Tooltip from '@material-ui/core/Tooltip';

const BlockchainAccount = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const copyMessage = (val) => {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Card>
        <CardContent>
          <Box mb={5}>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              Project Credentials
                    </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              You will find all your project related keys here.</Typography><Divider />
          </Box>
          <Box mb={3}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              The following is the Project Key that you will be used in the Niftron SDK config.
                    </Typography>
            <TextField
              fullWidth
              label="Project Key"
              margin="normal"
              name="projectKey"
              value={props.project.projectAccount.publicKey}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <Tooltip title="Copy" placement="top">
                    <Button onClick={e => {
                      e.preventDefault()
                      copyMessage(props.project.projectAccount.publicKey)
                      enqueueSnackbar("Project Key is copied to clipboard", { variant: "info" });

                    }}> <FileCopyIcon /> </Button>
                  </Tooltip>
                </InputAdornment>,
              }}
            />
          </Box>
          <Box mb={3}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              The following is the Project Secret Key.
                    </Typography>
            <CryptingText encryptedText={props.project.projectAccount.encryptedSecret} label={"Project Secret Key"} name={"projectSecretKey"} />
          </Box>


          <Box mb={3}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              The following is the Project Issuer that you will be used as an Issuer for your Stellar Tokens.
                    </Typography>
            <TextField
              fullWidth
              label="Project Issuer"
              margin="normal"
              name="secretKey"
              value={props.project.projectIssuer.publicKey}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <Tooltip title="Copy" placement="top">
                    <Button onClick={e => {
                      e.preventDefault()
                      copyMessage(props.project.projectIssuer.publicKey)
                      enqueueSnackbar("Project Issuer is copied to clipboard", { variant: "info" });
                    }}><FileCopyIcon />
                    </Button>
                  </Tooltip>
                </InputAdornment>,
              }}
            />
          </Box>
          <Box mb={3}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              The following is the Project Issuer Secret Key.
                    </Typography>
            <CryptingText encryptedText={props.project.projectIssuer.encryptedSecret} label={"Project Issuer Secret Key"} name={"projectIssuerSecretKey"} />
          </Box>
        </CardContent>
      </Card>
    </Box>

  );
};

export default BlockchainAccount;
