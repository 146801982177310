/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Container,

  Typography,
  makeStyles
} from '@material-ui/core';
import { useNavigate, useLocation } from "react-router"
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { claimCoupon } from "src/services/userManagement"
const signInMethodsList = [
  {
    value: 'GLOBAL',
    label: 'Niftron Global Sign-In'
  },
  {
    value: 'PROJECT',
    label: 'Project Specific Sign-In'
  },
];
const userTypeList = [
  {
    value: '0',
    label: 'Niftron Creator Account'
  },
  {
    value: '1',
    label: 'Niftron Collector Account'
  },
];
const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Redeem = ({ className, authUser, ...rest }) => {
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  return (

    <Card>
      <CardContent>
        <Formik
          initialValues={{
            code: '',
          }}
          validationSchema={
            Yup.object().shape({
              code: Yup.string().max(255).required('Promo Code is required'),
            })
          }
          onSubmit={async (values) => {
            try {
              await claimCoupon(authUser.publicKey, values.code)
              enqueueSnackbar("Redeemed", { variant: "success" });
            } catch (e) {
              enqueueSnackbar(e.message, { variant: "error" });
              console.log(e.message)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={5}>
                  <Typography
                    color="textPrimary"
                    variant="h3"
                  >
                    Redeem
                    </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use your niftron promo codes here to redeem offers.
                    </Typography><Divider />
                </Box>
                <Box mb={3}>
                  <TextField
                    error={Boolean(touched.code && errors.code)}
                    fullWidth
                    helperText={touched.code && errors.code}
                    label="Promo Code"
                    margin="normal"
                    name="code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.code}
                    variant="outlined"
                  />
                </Box>
                <Box my={2}>
                  <Container maxWidth="sm">  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Redeem
                    </Button>  </Container>
                </Box>
              </form>
            )}
        </Formik>

      </CardContent>


      <Divider />
    </Card>

  );
};

Redeem.propTypes = {
  className: PropTypes.string
};

export default Redeem;
