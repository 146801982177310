/* eslint-disable */

import axios from "axios";
import {
     StellarUrlTest, StellarUrl, NiftronIssuerPublicKey, NiftronDistributorPublicKey,NiftronApi
} from "src/variables/constants";
// import Niftron from "src/baas/config";
// import sha256 from "sha256";
import store from "src/redux/reducers"
import {
    ADD_SECRET_KEY, ADD_LOADING_MESSAGE, ADD_LOADING_VALUE, ADD_LOADING
} from '../redux/constants/ActionTypes'
// import { buildProjectAccountXDR, buildProjectXDR, signXDR } from "src/services/XDRBuilder"
// import { Keypair } from "stellar-sdk";
import { Server } from "stellar-sdk";
export async function claimCoupon(claimerPublicKey, code) {
    try {
        store.dispatch({ type: ADD_LOADING, text: true })
        store.dispatch({ type: ADD_LOADING_MESSAGE, text: "Verifying Promo Code ..." })

        let token;
        if (localStorage.getItem("niftoken") != null) {
            token = localStorage.getItem("niftoken");
        }
        let postBody = {
            claimerPublicKey,
            merchantPublicKey: NiftronDistributorPublicKey,
            code
        }
        const res = await axios.post(NiftronApi + "/rewards/claimCoupon", postBody, {
            headers: {
                'Authorization': "bearer " + token,
                "Content-Type": "application/json"
            }
        });
        if (res === null) {
            store.dispatch({ type: ADD_LOADING, text: false })
            throw new Error("Promo Code claiming failed");
        }
        store.dispatch({ type: ADD_LOADING, text: false })

        switch (res.status) {
            case 200: return res.status;
            case 201: throw new Error("Invalid merchant");
            case 202: throw new Error("Promo Code code not found");
            case 203: throw new Error("Account not found in niftron");
            case 204: throw new Error("Only developer accounts can redeem credits");
            case 205: throw new Error("Promo Code already claimed");
            case 206: throw new Error("Promo Code limit reached");
            case 207: throw new Error("Promo Code expired");
            case 400: throw new Error("Promo Code claiming failed");
            case null: throw new Error("Promo Code claiming failed");
            default: throw new Error("Promo Code claiming failed");
        }

    } catch (err) {
        store.dispatch({ type: ADD_LOADING, text: false })
        console.log(err);
        throw err;
    }
}


export async function isPublic(publicKey) {
    try {
        let server = new Server(StellarUrl);
        await server.loadAccount(publicKey);
        return true;
    } catch (err) {
        return false;
    }
}

export async function isTestNet(publicKey) {
    try {
        let server = new Server(StellarUrlTest);
        await server.loadAccount(publicKey);
        return true;
    } catch (err) {
        return false;
    }
}

export async function isNiftronPublicUser(publicKey) {
    try {
        let server = new Server(StellarUrl);
        let sourceAccount = await server.loadAccount(publicKey);
        if (
            sourceAccount.thresholds.high_threshold === 2 &&
            sourceAccount.thresholds.low_threshold === 2 &&
            sourceAccount.thresholds.med_threshold === 2
        ) {
            sourceAccount.signers.forEach(function (signer) {
                if (signer.key === NiftronDistributorPublicKey && signer.weight === 1) {
                    return true
                }
            });
        }
        return false;
    } catch (err) {
        // console.log(err);
        return false;
    }
}

export async function isNiftronTestUser(publicKey) {
    try {
        let server = new Server(StellarUrlTest);
        let sourceAccount = await server.loadAccount(publicKey);
        if (
            sourceAccount.thresholds.high_threshold === 2 &&
            sourceAccount.thresholds.low_threshold === 2 &&
            sourceAccount.thresholds.med_threshold === 2
        ) {
            sourceAccount.signers.forEach(function (signer) {
                if (signer.key === NiftronDistributorPublicKey && signer.weight === 1) {
                    return true
                }
            });
        }
        return false;
    } catch (err) {
        // console.log(err);
        return false;
    }
}



export async function getTestTokenByIssuer(publicKey) {
    try {
        let server = new Server(StellarUrlTest);
        const assets = await server.assets()
            .forIssuer(publicKey)
            .call()
        // .then(res => {
        //     console.log(res.records)
        //     return res.records;
        // }).catch((er) => {
        //     console.log(err);

        //     return null;

        // })
        return assets.records
    } catch (err) {
        console.log(err);
        return null;
    }
}

export async function getWalletBalance(publicKey) {

    try {

        let assets = [];

        var server = new Server(StellarUrl);
        // the JS SDK uses promises for most actions, such as retrieving an account
        const account = await server.loadAccount(publicKey);
        if (account === null) {
            return null
        }
        account.balances.forEach(function (balance) {
            // @ts-ignore
            // ////////console.log('Asset_code:', balance.asset_code, ', Balance:', balance.balance);
            let bal = parseFloat(balance.balance)
            let lim = parseFloat(balance.limit)

            // @ts-ignore
            assets.push({ 'assetCode': balance.asset_code, 'balance': bal.toFixed(0), 'limit': lim.toFixed(0) });
        });
        // assets.pop();
        ////////console.log(assets)
        return assets;
    } catch (err) {
        return null;
    }
}

export async function getTokenBalanceFromStellar(publicKey) {
    try {
        let assets = [];
        let server = new Server(StellarUrl);
        let sourceAccount;
        try {
            sourceAccount = await server.loadAccount(publicKey);
        } catch (err) {
            // //console.log(err)
            try {
                server = new Server(StellarUrlTest);
                sourceAccount = await server.loadAccount(publicKey);
            } catch (err2) {
                // //console.log(err2)
                return null
            }
        }
        sourceAccount.balances.forEach(function (balance) {
            // @ts-ignore
            // ////////console.log('Asset_code:', balance.asset_code, ', Balance:', balance.balance);
            // let bal = parseFloat(balance.balance)
            // let lim = parseFloat(balance.limit)
            if (balance.asset_type != "native" && balance.asset_issuer != NiftronIssuerPublicKey && balance.balance > 0.0000000) {
                assets.push({
                    assetCode: balance.asset_code, balance: balance.balance,
                    limit: balance.limit, issuer: balance.asset_issuer
                });
            }
        });
        // assets.pop();
        ////////console.log(assets)
        return assets;

        // return account.balances
    } catch (err) {
        //console.log(err)
        return null;
    }
}

export async function getNiftronCreditBalance(publicKey, test) {
    try {
        let response = null
        let server = new Server(test ? StellarUrlTest : StellarUrl);
        let sourceAccount;
        try {
            sourceAccount = await server.loadAccount(publicKey);
        } catch (err) {
            try {
                server = new Server(StellarUrlTest);
                sourceAccount = await server.loadAccount(publicKey);
            } catch (err2) {
                console.log(err2)
                return null
            }
        }
        sourceAccount.balances.forEach(function (balance) {
            // @ts-ignore
            // console.log('Asset_code:', balance.asset_code, ', Balance:', balance.balance);
            let bal = parseFloat(balance.balance)

            // let lim = parseFloat(balance.limit)
            if (balance.asset_type != "native" && balance.asset_issuer == NiftronIssuerPublicKey && balance.asset_code == "NIFTRON") {
                response = {
                    assetCode: balance.asset_code, balance: bal,
                    limit: balance.limit, issuer: balance.asset_issuer
                };

            }
        });
        return response
    } catch (err) {
        console.log(err)
        return null;
    }
}

export async function getXLMBalance(publicKey, test) {
    try {
        let response = null
        let server = new Server(test ? StellarUrlTest : StellarUrl);
        let sourceAccount;
        try {
            sourceAccount = await server.loadAccount(publicKey);
        } catch (err) {
            try {
                server = new Server(StellarUrlTest);
                sourceAccount = await server.loadAccount(publicKey);
            } catch (err2) {
                console.log(err2)
                return null
            }
        }
        sourceAccount.balances.forEach(function (balance) {
            // @ts-ignore
            // console.log('Asset_code:', balance.asset_code, ', Balance:', balance.balance);
            let bal = parseFloat(balance.balance)

            // let lim = parseFloat(balance.limit)
            if (balance.asset_type == "native") {
                response = {
                    assetCode: balance.asset_code, balance: bal,
                    limit: balance.limit, issuer: balance.asset_issuer
                };

            }
        });
        return response
    } catch (err) {
        console.log(err)
        return null;
    }
}