/* eslint-disable */
import React, { useState } from 'react';
import {
  Card,
  Box,
  Container,
  makeStyles, Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
// import Results from './Results';
// import Toolbar from './Toolbar';
// import data from './data';
import { useSelector } from 'react-redux'
import TokenizationMethod from './TokenizationMethod.js';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'src/components/TabPanel';
import { useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TokenizationListView = (props) => {
  const classes = useStyles();
  // const [users] = useState(data);
  const project = useSelector(state => state.ProjectKey)
  const projects = useSelector(state => state.Projects)

  const [value, setValue] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page
      className={classes.root}
      title="Tokenization"
    >
      <Container maxWidth="xl">
        <Card>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          // centered
          >
            <Tab label="Blockchain Selection" />
            <Tab label="Tokens" disabled />
            <Tab label="Usage" disabled />
          </Tabs>
        </Card>
        <TabPanel value={value} index={0}>
          {projects && projects.length > 0 && projects.map((item, i) => (
            <>
              {item.projectAccount.publicKey == project &&
                <TokenizationMethod projectData={item} key={i} />
              }
            </>
          ))}
        </TabPanel>
      </Container>
    </Page>
  );
};

export default TokenizationListView;
