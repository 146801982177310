/* eslint-disable */
import React from 'react';
import { Navigate } from 'react-router-dom';
import ConsoleLayout from 'src/layouts/ConsoleLayout';
import AuthLayout from 'src/layouts/AuthLayout';

import DashboardView from 'src/views/dashboard';
import NotFoundView from 'src/views/errors/NotFoundView';
import SettingsView from 'src/views/settings';
import AuthenticationView from 'src/views/authentication';
import TokenizationView from 'src/views/tokenization';
import TransactionView from 'src/views/transaction';
import DataView from 'src/views/data';
import Consent from 'src/views/consents';
import Approvals from 'src/views/approvals';
import Credits from 'src/views/credits/index';
import Activate from 'src/views/activate';
import Credentials from 'src/views/credentials';
import ProjectDetails from 'src/views/projectDetails';
import CreateProjectView from 'src/views/createProject/CreateProjectView';

const routes = [
  // {
  //   path: '/payment',//for creators
  //   element: <AuthLayout />,
  //   children: [
  //     { path: 'activate', element: <Activate /> },
  //     { path: 'success', element: <Activate /> },
  //     { path: '/', element: <Navigate to="/activate" /> },
  //     { path: '*', element: <Navigate to="/404" /> }
  //   ]
  // },
  {
    path: '/project',//for creators
    element: <AuthLayout />,
    children: [
      { path: 'create', element: <CreateProjectView /> },
      { path: '/', element: <Navigate to="/create" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',//for users
    element: <ConsoleLayout />,
    children: [
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'authentication', element: <AuthenticationView /> },
      { path: 'tokenization', element: <TokenizationView /> },
      { path: 'transactions', element: <TransactionView /> },
      { path: 'data', element: <DataView /> },
      { path: 'consents', element: <Consent /> },
      { path: 'approvals', element: <Approvals /> },
      { path: 'projectDetails', element: <ProjectDetails /> },
      { path: 'createProject', element: <CreateProjectView /> },
      { path: 'credits', element: <Credits /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'credentials', element: <Credentials /> },
      { path: '404', element: <NotFoundView /> },
      // { path: '/', element: <Navigate to="/authentication" /> },
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
