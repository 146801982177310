export const styles = theme => ({
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: "100%",
        background: '#333',
        // [theme.breakpoints.up('sm')]: {
        //     height: 300,
        // },
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
    },
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    imgContainer: {
        position: 'relative',
        flex: 1,
        padding: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
})
