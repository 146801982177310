/* eslint-disable */

import {
    ADD_LOADING_MESSAGE,
    ADD_SECRET_KEY,
    ADD_LOADING_VALUE,
    ADD_LOADING,
    ADD_PROJECT_KEY,
    ADD_LOADING_MESSAGE_TYPE,
    ADD_PROJECTS,
    ADD_DEMO_STEPS,
    ADD_HELPERS

} from '../constants/ActionTypes'
import {
    // createProjectDemo,
    LayoutIntroDemo,
    // GettingStartedDemo,
    // AuthenticationDemo,
    // TokenizationDemo
} from "src/demos/Demo"

const initialState = {
    LoadingMessage: "Please remain patient",
    LoadingMessageType: "0",
    LoadingValue: "100",
    Loading: false,
    SecretKey: "",
    ProjectKey: "",
    Projects: [],
    DemoSteps: LayoutIntroDemo,
    Helpers: null

}

export default function variables(state = initialState, action) {
    switch (action.type) {
        case ADD_SECRET_KEY:
            state.SecretKey = action.text
            return state
        case ADD_PROJECT_KEY:
            state.ProjectKey = action.text
            return state
        case ADD_PROJECTS:
            state.Projects = action.text
            return state
        case ADD_LOADING_MESSAGE:
            state.LoadingMessage = action.text
            return state
        case ADD_LOADING_MESSAGE_TYPE:
            state.LoadingMessageType = action.text
            return state
        case ADD_LOADING_VALUE:
            state.LoadingValue = action.text
            return state
        case ADD_LOADING:
            state.Loading = action.text
            return state
        case ADD_DEMO_STEPS:
            state.DemoSteps = action.text
            return state
        case ADD_HELPERS:
            state.Helpers = action.text
            return state
        default:
            return state
    }
}
