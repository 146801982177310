/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  // Box,
  // Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useNavigate, useLocation } from "react-router"
import NiftronSigner from "src/components/NiftronSigner"
import Niftron from "src/baas/config";
import { useSnackbar } from 'notistack';

const patternSK = /^[S,s][a-zA-Z0-9]{55}$/;

const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Notifications = ({ className, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  let navigate = useNavigate();
  let location = useLocation();
  const [authUser, setAuthUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    Niftron.user.onAuthStateChanged(
      authUser => {
        setAuthUser(authUser)
      },
      err => {
        if (err) {
          console.log(err)
        }
      }
    );
  }, []);
  const [lightMode, setLightMode] = React.useState(localStorage.getItem("theme") &&
    localStorage.getItem("theme") === "LIGHT" ? true : false)

  const [autoSign, setAutoSign] = React.useState(localStorage.getItem("secretKey") &&
    patternSK.test(localStorage.getItem("secretKey")) ? true : false)

  const setSecret = (secretKey) => {
    setAutoSign(true)
    localStorage.setItem("secretKey", secretKey)
    enqueueSnackbar(`Autosign activated`, { variant: "success" });
  }

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      {authUser && <NiftronSigner open={open} setOpen={setOpen} authTypeInput={authUser.authType} publicKey={authUser.publicKey}
        encryptedSecret={authUser.encryptedSecret} onSecretKey={setSecret} hideAutoSign={true}/>}
      <Card>
        <CardHeader
          subheader="Manage the preferences"
          title="Preferences"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h6"
              >
                Appearance
              </Typography>
              <FormControlLabel
                control={(
                  <Checkbox checked={lightMode}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setLightMode(true)
                        localStorage.setItem("theme", "LIGHT")
                        navigate(location, { replace: true });
                      } else {

                        setLightMode(false)
                        localStorage.setItem("theme", "DARK")
                        navigate(location, { replace: true });
                      }
                    }}

                  />
                )}

                label="Light Mode"
              />
            </Grid>
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h6"
              >
                Auto Signer
              </Typography>
              <FormControlLabel
                control={(
                  <Checkbox checked={autoSign}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setOpen(true)
                        // navigate(location, { replace: true });
                      } else {

                        setAutoSign(false)
                        localStorage.removeItem("secretKey")
                        navigate(location, { replace: true });
                      }

                    }}

                  />
                )}

                label="Auto Sign"
              />
            </Grid>
          </Grid>
        </CardContent>
        {/* <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </Box> */}
      </Card>
    </form>
  );
};

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
