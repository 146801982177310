// /* eslint-disable */ 
import React from 'react';

const Logo = (props) => {
  return (
    <img
      height="43px"
      alt="Logo"
      src="https://niftron-logo.s3.amazonaws.com/NIFTRON+LOGO+FULL"
      {...props}
    />
  );
};

export default Logo;
