// /* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
    Box,
    Typography
} from '@material-ui/core';

import PropTypes from 'prop-types';
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.dark,
        padding: theme.spacing(2, 4, 3),
    },
}));

const MessagePopUp = ({ message, open, setOpen }) => {
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Modal 
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Box mb={3}>

                            {message}
                        </Box>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}
MessagePopUp.propTypes = {
    // children: PropTypes.node.isRequired,
    message: PropTypes.string,
    open: PropTypes.bool,
    setOpen: PropTypes.func,

};
export default MessagePopUp;
