/* eslint-disable */
require('dotenv').config()
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux'
import store from 'src/redux/reducers'
// import ScrollToTop from 'src/components/ScrollToTop';

import "src/assets/index.css"
const hist = createBrowserHistory();

ReactDOM.render((
  <Provider store={store}>
    <SnackbarProvider maxSnack={3}>
      <BrowserRouter history={hist}>
        <App />
      </BrowserRouter>
    </SnackbarProvider>
  </Provider>
), document.getElementById('root'));

serviceWorker.unregister();
