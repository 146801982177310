import 'react-perfect-scrollbar/dist/css/styles.css';
/* eslint-disable */
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import themeDark from 'src/themeDark';
import LinearProgress from '@material-ui/core/LinearProgress';

import routes from 'src/routes';
import { useSelector } from 'react-redux'
import MessageLoader2 from 'src/components/MessageLoader2'
import MessageLoader from 'src/components/MessageLoader'

import { useDencrypt } from "use-dencrypt-effect";

const App = () => {
  const routing = useRoutes(routes);
  const loading = useSelector(state => state.Loading)
  const loadingMessageType = useSelector(state => state.LoadingMessageType)
  const loadingMessage = useSelector(state => state.LoadingMessage)
  const { result, dencrypt } = useDencrypt({ interval: 1 });

  React.useEffect(() => {
    dencrypt(loadingMessage)
  }, [loadingMessage]);
  return (
    <ThemeProvider theme={
      localStorage.getItem("theme") &&
        localStorage.getItem("theme") == "LIGHT" ? theme : themeDark}>
      <GlobalStyles />
      {loading &&
        <LinearProgress style={{ zIndex: "10000000" }} variant={"indeterminate"} />
      }
      {loading && loadingMessage &&
        <MessageLoader2 message={result} type={loadingMessageType} />
      }
      {routing}

    </ThemeProvider >
  );
};

export default App;
