import React, { useState } from "react";
import { useDencrypt } from "use-dencrypt-effect";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Tooltip from '@material-ui/core/Tooltip';
import {
    Button,
    TextField
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Niftron from "src/baas/config";
import NiftronSigner from "src/components/NiftronSigner"
import InputAdornment from '@material-ui/core/InputAdornment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { NiftronKeypair } from "niftron-sdk";

const CryptingText = ({ encryptedText, label, name }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { result, dencrypt } = useDencrypt({ interval: 1 });
    const [text, setText] = useState(encryptedText)
    const [isEncrypted, setIsEncrypted] = useState(true)
    const [authUser, setAuthUser] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [decryptedText, setDecryptedText] = useState("")

    React.useEffect(() => {
        Niftron.user.onAuthStateChanged(
            authUser => {
                setAuthUser(authUser)
            },
            err => {
                if (err) {
                    console.log(err)
                }
            }
        );
    }, []);

    React.useEffect(() => {
        dencrypt(text)
    }, [text, dencrypt]);

    const decrypt = (secret) => {
        const decryptedValue = Niftron.utils.decryptSecret(encryptedText, secret)
        setText(decryptedValue)
        setDecryptedText(decryptedValue)
        setIsEncrypted(false)
    }

    const copyMessage = (val) => {
        const selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
    }
    return <>
        {authUser && <NiftronSigner open={open} setOpen={setOpen} authTypeInput={authUser.authType} publicKey={authUser.publicKey}
            encryptedSecret={authUser.encryptedSecret} onSecretKey={decrypt} />}
        <TextField
            fullWidth
            label={label + ` (${isEncrypted ? "ENCRYPTED" : "DECRYPTED"})`}
            margin="normal"
            name={name}
            value={isEncrypted ? encryptedText : result}
            variant="outlined"
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    {isEncrypted && <Tooltip title="Decrypt" placement="top">
                        <Button onClick={e => {
                            e.preventDefault()
                            if (localStorage.getItem("secretKey")) {
                                if (authUser.publicKey === NiftronKeypair.fromSecret(localStorage.getItem("secretKey")).publicKey()) {
                                    decrypt(localStorage.getItem("secretKey"))
                                    return
                                }
                            } else {
                                setOpen(true)
                                return
                            }
                            enqueueSnackbar(`${label} is decrypted`, { variant: "info" });
                        }}> <VpnKeyIcon />
                        </Button>
                    </Tooltip>
                    }
                    {!isEncrypted && <Tooltip title="Copy" placement="top">
                        <Button onClick={e => {
                            e.preventDefault()
                            copyMessage(decryptedText)
                            enqueueSnackbar(`${label} is copied to clipboard`, { variant: "info" });
                        }}> <FileCopyIcon />
                        </Button>
                    </Tooltip>}
                </InputAdornment>,
            }}
        /></>
}
CryptingText.propTypes = {
    // children: PropTypes.node.isRequired,
    encryptedText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string
};
export default CryptingText;
