/* eslint-disable */

import axios from 'axios';
import { NiftronApi } from 'src/variables/constants';
import Niftron from 'src/baas/config';
import store from 'src/redux/reducers';
import {
  ADD_LOADING_MESSAGE,
  ADD_LOADING,
  ADD_PROJECT_KEY,
  ADD_PROJECTS
} from '../redux/constants/ActionTypes';
import {
  buildProjectAccountXDR,
  buildProjectXDR,
  signXDR
} from 'src/services/XDRBuilder';
import { Keypair } from 'stellar-sdk';

export async function createProject(
  values,
  keypair,
  authUser,
  isOnboarding,
  onBoardingLevel
) {
  try {
    // console.log(values)
    store.dispatch({ type: ADD_LOADING, text: true });

    //create niftron project stellar account (the project network will be determined by the creator account network)
    //call builder project account
    store.dispatch({
      type: ADD_LOADING_MESSAGE,
      text: 'Building niftron project credentials ...'
    });

    const projectAccountKeypair = Keypair.random();
    const projectIssuerKeypair = Keypair.random();

    const projectAccountXDR = await buildProjectAccountXDR(
      keypair,
      projectAccountKeypair.publicKey(),
      projectIssuerKeypair.publicKey()
    );

    // console.log(projectAccountXDR)

    //sign project account xdrs
    store.dispatch({
      type: ADD_LOADING_MESSAGE,
      text: 'Validating project credentials ...'
    });

    let signedProjectAccountXDRs = [];
    for (let i = 0, len = projectAccountXDR.xdrs.length; i < len; i++) {
      // console.log(projectAccountXDR.xdrs[i].xdr)
      const signedXDR = await signXDR(
        projectAccountXDR.xdrs[i].xdr,
        keypair.secret()
      );
      // console.log(signedXDR)
      signedProjectAccountXDRs.push(signedXDR);
    }

    store.dispatch({
      type: ADD_LOADING_MESSAGE,
      text: 'Creating niftron project ...'
    });

    //submit project account xdrs
    await addProjectAccount(signedProjectAccountXDRs);

    //add creator as a project account signer
    //call builder project account signer
    const addSignerToProjectXDR = await buildProjectXDR(
      keypair,
      projectAccountKeypair.publicKey(),
      projectIssuerKeypair.publicKey()
    );

    //sign project account signer xdrs
    store.dispatch({
      type: ADD_LOADING_MESSAGE,
      text: 'Building project contributor transaction ...'
    });
    let signedAddSignerToProjectXDRs = [];

    const signedXDR1 = await signXDR(
      addSignerToProjectXDR.xdrs[0].xdr,
      projectAccountKeypair.secret()
    );
    const signedXDR2 = await signXDR(
      addSignerToProjectXDR.xdrs[1].xdr,
      projectIssuerKeypair.secret()
    );

    // console.log(signedXDR)
    signedAddSignerToProjectXDRs.push(signedXDR1);
    signedAddSignerToProjectXDRs.push(signedXDR2);

    store.dispatch({
      type: ADD_LOADING_MESSAGE,
      text: 'Adding developer as project contributor ...'
    });
    //submit project details to add to niftron
    const response = await addProject(
      values.name,
      keypair.publicKey(),
      {
        publicKey: projectAccountKeypair.publicKey(),
        accountType: '2',
        encryptedSecret: Niftron.utils.encryptSecret(
          projectAccountKeypair.secret(),
          keypair.secret()
        )
      },
      {
        publicKey: projectIssuerKeypair.publicKey(),
        accountType: '3',
        encryptedSecret: Niftron.utils.encryptSecret(
          projectIssuerKeypair.secret(),
          keypair.secret()
        )
      },
      [
        {
          publicKey: keypair.publicKey(),
          accountType: '0',
          authType: authUser.authType
        }
      ],
      [
        {
          blockchainName: 'STELLAR',
          network: 'TEST'
        }
      ],
      signedAddSignerToProjectXDRs,
      isOnboarding,
      onBoardingLevel
    );

    store.dispatch({
      type: ADD_LOADING_MESSAGE,
      text: 'Syncing project data ...'
    });

    setTimeout(async () => {
      const projectsRes = await getProjectByContributor(authUser.publicKey);
      if (projectsRes.data.length > 0) {
        store.dispatch({
          type: ADD_PROJECT_KEY,
          text: projectsRes.data[0].projectAccount.publicKey
        });
        store.dispatch({ type: ADD_PROJECTS, text: projectsRes.data });
      }
    }, 1000);

    // window.location.replace("/");
    store.dispatch({ type: ADD_LOADING, text: false });

    return response;
    // return null
  } catch (error) {
    store.dispatch({ type: ADD_LOADING, text: false });
    console.log(error);
    throw error;
  }
}

export async function addProjectAccount(xdrs) {
  try {
    const postBody = { xdrs };
    let token;
    if (localStorage.getItem('niftoken') != null) {
      token = localStorage.getItem('niftoken');
    }
    const res = await axios.post(
      `${NiftronApi}/projects/projectAccount`,
      postBody,
      {
        headers: {
          Authorization: 'bearer ' + token,
          'Content-Type': 'application/json'
        }
      }
    );

    if (res == null) {
      throw new Error('Adding project account failed');
    }

    switch (res.status) {
      case 200:
        return res.status;
      case 201:
        throw new Error('Creator account not found');
      case 400:
        throw new Error('Creating niftron project failed');
      case null:
        throw new Error('Creating niftron project failed');
      default:
        throw new Error('Creating niftron project failed');
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function addProject(
  name,
  creator,
  projectIssuer,
  projectAccount,
  contributors,
  blockchains,
  xdrs,
  isOnboarding,
  onBoardingLevel
) {
  try {
    const postBody = {
      name,
      creator,
      projectIssuer,
      projectAccount,
      contributors,
      blockchains,
      xdrs,
      isOnboarding,
      onBoardingLevel
    };
    let token;
    if (localStorage.getItem('niftoken') != null) {
      token = localStorage.getItem('niftoken');
    }
    const res = await axios.post(`${NiftronApi}/projects`, postBody, {
      headers: {
        Authorization: 'bearer ' + token,
        'Content-Type': 'application/json'
      }
    });

    if (res == null) {
      throw new Error('Adding project account failed');
    }

    switch (res.status) {
      case 200:
        return res.status;
      case 201:
        throw new Error('Project account not found in blockchain');
      case 202:
        throw new Error('Project is already created');
      case 400:
        throw new Error('Creating niftron project failed');
      case null:
        throw new Error('Creating niftron project failed');
      default:
        throw new Error('Creating niftron project failed');
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function editProject(key, values) {
  try {
    store.dispatch({ type: ADD_LOADING, text: true });
    store.dispatch({ type: ADD_LOADING_MESSAGE, text: 'Updating project ...' });

    const postBody = values;
    let token;
    if (localStorage.getItem('niftoken') != null) {
      token = localStorage.getItem('niftoken');
    }
    const res = await axios.put(
      `${NiftronApi}/projects/${key}`,
      postBody,
      {
        headers: {
          Authorization: 'bearer ' + token,
          'Content-Type': 'application/json'
        }
      }
    );

    if (res == null) {
      throw new Error('Updating project failed');
    }

    switch (res.status) {
      case 200:
        Niftron.user.getCurrentUser(
          async authUser => {
            const projects = await getProjectByContributor(authUser.publicKey);
            store.dispatch({ type: ADD_PROJECTS, text: projects.data });
          },
          err => {
            if (err) {
              console.log(err);
            }
          }
        );

        // store.dispatch({ type: ADD_PROJECTS, text: projects });
        store.dispatch({ type: ADD_LOADING, text: false });
        return res.status;
      case 201:
        throw new Error('Project not found');
      case 403:
        throw new Error('You do not have permission');
      case 400:
        throw new Error('Updating niftron project failed');
      case null:
        throw new Error('Updating niftron project failed');
      default:
        throw new Error('Updating niftron project failed');
    }
  } catch (error) {
    store.dispatch({ type: ADD_LOADING, text: false });

    console.log(error);
    throw error;
  }
}

export async function getProjectByPublicKey(publicKey) {
  try {
    // store.dispatch({ type: ADD_LOADING, text: true })
    // store.dispatch({ type: ADD_LOADING_MESSAGE, text: "Looking for your project" })
    let token;
    if (localStorage.getItem('niftoken') != null) {
      token = localStorage.getItem('niftoken');
    }
    const res = await axios.get(`${NiftronApi}/projects/${publicKey}`, {
      headers: {
        Authorization: 'bearer ' + token,
        'Content-Type': 'application/json'
      }
    });

    store.dispatch({ type: ADD_LOADING, text: false });
    if (res == null) {
      throw new Error('Failed to get project');
    }
    return res.data;
  } catch (error) {
    store.dispatch({ type: ADD_LOADING, text: false });
    throw new Error('Failed to get project');
  }
}

export async function getProjectByContributor(publicKey) {
  try {
    store.dispatch({ type: ADD_LOADING, text: true });
    store.dispatch({
      type: ADD_LOADING_MESSAGE,
      text: 'Syncing project data ...'
    });
    let token;
    if (localStorage.getItem('niftoken') != null) {
      token = localStorage.getItem('niftoken');
    }
    const res = await axios.get(
      `${NiftronApi}/projects/projectsByContributor/${publicKey}`,
      {
        headers: {
          Authorization: 'bearer ' + token,
          'Content-Type': 'application/json'
        }
      }
    );

    store.dispatch({ type: ADD_LOADING, text: false });
    if (res == null) {
      throw new Error('Failed to get projects');
    }
    return res.data;
  } catch (error) {
    store.dispatch({ type: ADD_LOADING, text: false });
    throw new Error('Failed to get projects');
  }
}
