/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Container,

  Typography,
  makeStyles
} from '@material-ui/core';
import { useNavigate, useLocation } from "react-router"
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { editProject } from "src/services/projectManagement"
const blockchains = [
  {
    value: 'ETH',
    label: 'Niftron Global Sign-In'
  },
  {
    value: 'PROJECT',
    label: 'Project Specific Sign-In'
  },
];
const userTypeList = [
  {
    value: '0',
    label: 'Niftron Creator Account'
  },
  {
    value: '1',
    label: 'Niftron Collector Account'
  },
];
const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const TokenizationMethod = ({ className, projectData, ...rest }) => {
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [darkMode, setDarkMode] = React.useState(localStorage.getItem("theme") &&
    localStorage.getItem("theme") === "DARK" ? true : false)

  // React.useEffect(() => {

  // }, []);
  return (

    <Card>{projectData &&
      <CardContent>
        <Formik
          initialValues={{
            blockchainName: projectData.blockchains[0]?.blockchainName ? projectData.blockchains[0].blockchainName : '',
            network: projectData.blockchains[0]?.network ? projectData.blockchains[0].network : '',
          }}
          validationSchema={
            Yup.object().shape({
              blockchainName: Yup.string().max(255).required('Blockchain Name is required'),
              network: Yup.string().max(255).required('Network is required'),
            })
          }
          onSubmit={async (values) => {
            try {

              await editProject(
                projectData.projectAccount.publicKey,
                {
                  blockchains: [
                    {
                      blockchainName: values.blockchainName,
                      network: values.network
                    }
                  ]
                }
              )
              enqueueSnackbar("Project Updated", { variant: "success" });

            } catch (e) {
              enqueueSnackbar(e.message, { variant: "error" });
              console.log(e.message)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={5}>
                  <Typography
                    color="textPrimary"
                    variant="h3"
                  >
                    Tokenization
                    </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Setup your tokenization preferences here
                    </Typography><Divider />
                </Box>


                <Box mb={3}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    The following setting let's you decide the blockchain that you will be using to tokenize. The pricing may differ for each blockchain.
                    </Typography>
                </Box>
                <Box mb={3}>
                  <TextField
                    fullWidth
                    label="Select A Blockchain"
                    name="blockchainName"
                    onChange={handleChange}
                    // required
                    select
                    SelectProps={{ native: true }}
                    value={values.blockchainName}
                    variant="outlined"
                  >
                    {/* <option key={"0"} value={""} >None</option> */}
                    <option key={"1"} value={"STELLAR"} >Stellar</option>
                    <option key={"2"} value={"ETHEREUM"} disabled>Ethereum</option>
                  </TextField>
                </Box>
                <Box mb={3}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    The following setting let's you decide the type of niftron
                    account your users will use and hences deciding the fuctionalities that your offer them.
                    </Typography>
                </Box>
                <Box my={2}>

                  <TextField
                    fullWidth
                    label="Select Network Type"
                    name="network"
                    onChange={handleChange}
                    // required
                    select
                    SelectProps={{ native: true }}
                    value={values.network}
                    variant="outlined"
                  >
                    <option key={"1"} value={"TEST"} >Test</option>
                    <option key={"2"} value={"LIVE"} disabled>Live</option>
                  </TextField>
                </Box>
                <Box my={2}>
                  <Container maxWidth="sm">  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                    </Button>  </Container>
                </Box>
              </form>
            )}
        </Formik>

      </CardContent>


    }
      <Divider />
    </Card >

  );
};

TokenizationMethod.propTypes = {
  className: PropTypes.string
};

export default TokenizationMethod;
