/* eslint-disable */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors,
  Box
} from '@material-ui/core';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { StellarUrlTest } from "src/variables/constants";
import { Server } from "stellar-sdk";
import { getTestTokenByIssuer } from "src/services/userManagement";
const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  }
}));
let server = new Server(StellarUrlTest);


const TokensCreated = ({ className, project, ...rest }) => {
  const classes = useStyles();
  const [tokenizedCount, setTokenizedCount] = React.useState(0);
  const [tokenCount, setTokenCount] = React.useState(0);

  React.useEffect(() => {
    const fetch = async () => {
      let totalTokens = tokenizedCount
      const assets = await getTestTokenByIssuer(project.projectIssuer.publicKey)
      if (assets && assets.length > 0) {
        setTokenCount(assets.length)
        assets.forEach(asset => {
          totalTokens = totalTokens + parseFloat(asset.amount) * 10000000
        });
        setTokenizedCount(totalTokens)
      }
    }
    fetch();
  }, [project]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >TOKENS CREATED</Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {tokenCount}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >(Volume: {tokenizedCount})
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <ConfirmationNumberIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
          {/* <ArrowUpwardIcon className={classes.differenceIcon} />
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            16%
          </Typography> */}
          <Typography
            color="textSecondary"
            variant="caption"
          >
            This includes the tokens created specifically under this project
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

TokensCreated.propTypes = {
  className: PropTypes.string
};

export default TokensCreated;
