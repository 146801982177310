/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import ImageGetCrop from "src/components/ImageCropper/ImgDialog.js";
import { editProject } from "src/services/projectManagement"
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const Profile = ({ imgSrc, project, className, ...rest }) => {
  const classes = useStyles();
  const [image, setImage] = React.useState(imgSrc)
  const { enqueueSnackbar } = useSnackbar();

  const submitImage = async (image) => {
    try {
      await editProject(
        project.projectAccount.publicKey,
        {
          projectLogoURL: image
        }
      )
      setImage(image)
      enqueueSnackbar("Project Logo Updated", { variant: "success" });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
      console.log(e.message)
    }
  }
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={image}
          />
          {/* <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {user.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {`${user.city} ${user.country}`}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format('hh:mm A')} ${user.timezone}`}
          </Typography> */}
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <ImageGetCrop dogImg={image} setCroppedImage={submitImage} />
      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
