/* eslint-disable */
import React, { useState } from 'react';
import {
  Card,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
// import Results from './Results';
// import Toolbar from './Toolbar';
// import data from './data';
import { useSelector } from 'react-redux'
import Activate from './Activate.js';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'src/components/TabPanel';
import Niftron from "src/baas/config";

import Recharge from './Recharge.js';
import Redeem from './Redeem.js';
import { isNiftronPublicUser } from "src/services/userManagement";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreditsView = (props) => {
  const classes = useStyles();
  const [authUser, setAuthUser] = React.useState(null);
  const [isPublicUser, setIsPublicUser] = React.useState(true);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    Niftron.user.onAuthStateChanged(
      async (authUser) => {
        setAuthUser(authUser)
      },
      err => {
        if (err) {
          console.log(err)
        }
      }
    );
  }, []);

  return (
    <Page
      className={classes.root}
      title="Credits"
    >
      <Container maxWidth="xl">
        {/* {
          isPublicUser &&
          <> */}
            <Card>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              // centered
              >
                <Tab label="Redeem" />
                <Tab label="Recharge" disabled />

              </Tabs>
            </Card>

            <TabPanel value={value} index={0}>
              <Redeem authUser={authUser}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Recharge />
            </TabPanel>
          {/* </> */}
        {/* } */}
        {/* {
          !isPublicUser && <Activate />
        } */}
      </Container>
    </Page>
  );
};

export default CreditsView;
