
// /* eslint-disable */
import React, { useState, useCallback } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import Dropzone from 'react-dropzone';

import { styles } from './styles'
import Cropper from 'react-easy-crop'
import Button from '@material-ui/core/Button'
import getCroppedImg from './cropImage'

function Transition(props) {
    return <Slide direction="up" {...props} />
}

function ImgDialog({ classes, appBar, dogImg, setCroppedImage }) {
    const [open, setOpen] = useState(false)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    // const [croppedImage, setCroppedImage] = useState(null)
    const [image, setImage] = useState(dogImg)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                image,
                croppedAreaPixels,
                rotation
            )
            console.log('donee', { croppedImage })
            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation, setCroppedImage, image])

    const onClose = useCallback(() => {
        handleClose()
    }, [])


    return (
        <>
            <Dropzone
                onDrop={acceptedFiles => {
                    const reader = new FileReader()
                    reader.addEventListener("load", async () => {
                        console.log(reader.result)
                        setImage(reader.result)
                        handleClickOpen()
                        // showCroppedImage()
                    });
                    reader.readAsDataURL(acceptedFiles[0])
                }}
                multiple={false} accept={[".png", ".jpeg"]}>
                {({ getRootProps, getInputProps }) => (
                    // <div {...getRootProps({ className: '' })}>
                    //     <input {...getInputProps()} />
                    //     <Typography >Drop or select</Typography>
                    // </div>
                    <Button {...getRootProps({ className: '' })}
                        color="primary"
                        fullWidth
                        variant="text"
                    > <input {...getInputProps()} />
                        Upload logo
                    </Button>
                )}
            </Dropzone>
            <Dialog
                fullScreen
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            onClick={onClose}
                            aria-label="Close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="title"
                            color="inherit"
                            className={classes.flex}
                        >Cancel</Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.cropContainer}>
                    <Cropper
                        image={image}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={1 / 1}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
                <Button
                    onClick={() => {
                        showCroppedImage()
                        handleClose()
                    }}
                    variant="contained"
                    color="primary"
                    classes={{ root: classes.cropButton }}
                >Confirm</Button>
            </Dialog>
        </>
    )
}

export default withStyles(styles)(ImgDialog)
