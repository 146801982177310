/* eslint-disable */
import axios from 'axios';
import {
  niftronUserLambda,
  NiftronDistributorPublicKey,
  StellarUrl,
  StellarUrlTest,
  NiftronApi
} from 'src/variables/constants';
import { Server, Keypair, Transaction, Networks } from 'stellar-sdk';
//old
export async function buildProjectAccountXDR(
  keypair,
  projectAccountPublicKey,
  projectIssuerPublicKey
) {
  try {
    let token;
    if (localStorage.getItem('niftoken') != null) {
      token = localStorage.getItem('niftoken');
    }
    let postBody = {
      creatorPublicKey: keypair.publicKey(),
      merchantPublicKey: NiftronDistributorPublicKey,
      projectAccountPublicKey,
      projectIssuerPublicKey
    };
    const res = await axios.post(
      NiftronApi + '/xdrs/projectAccount',
      postBody,
      {
        headers: {
          Authorization: 'bearer ' + token,
          'Content-Type': 'application/json'
        }
      }
    );
    if (res === null) {
      return null;
    }
    const xdrs = res.data.data;
    // const secondaryPublicKey = res.data.metaData?.secondaryPublicKey
    return {
      xdrs //, secondaryPublicKey
    };
  } catch (err) {
    //console.log(err);
    return null;
  }
}

export async function buildProjectXDR(
  keypair,
  projectAccountPublicKey,
  projectIssuerPublicKey
) {
  try {
    let token;
    if (localStorage.getItem('niftoken') != null) {
      token = localStorage.getItem('niftoken');
    }
    let postBody = {
      creatorPublicKey: keypair.publicKey(),
      merchantPublicKey: NiftronDistributorPublicKey,
      projectAccountPublicKey,
      projectIssuerPublicKey
    };
    const res = await axios.post(
      NiftronApi + '/xdrs/project',
      postBody,
      {
        headers: {
          Authorization: 'bearer ' + token,
          'Content-Type': 'application/json'
        }
      }
    );
    if (res === null) {
      return null;
    }
    const xdrs = res.data.data;
    // const secondaryPublicKey = res.data.metaData?.secondaryPublicKey
    return {
      xdrs //, secondaryPublicKey
    };
  } catch (err) {
    //console.log(err);
    return null;
  }
}

export async function signXDR(xdr, secretKey) {
  try {
    let parsedTx;
    let publicKey;
    let server = new Server(StellarUrl);
    let keypair = Keypair.fromSecret(secretKey);
    let sourceAccount;
    try {
      parsedTx = new Transaction(xdr, Networks.PUBLIC);
      publicKey = parsedTx.source;
      sourceAccount = await server.loadAccount(keypair.publicKey());
    } catch (err) {
      try {
        server = new Server(StellarUrlTest);
        parsedTx = new Transaction(xdr, Networks.TESTNET);
        publicKey = parsedTx.source;
        sourceAccount = await server.loadAccount(keypair.publicKey());
      } catch (err2) {
        return null;
      }
    }
    parsedTx.sign(keypair);
    let x = parsedTx
      .toEnvelope()
      .toXDR()
      .toString('base64');
    return x;
  } catch (err) {
    console.log(err);
    return null;
  }
}
export async function signXDRTestNet(xdr, secretKey) {
  try {
    let keypair = Keypair.fromSecret(secretKey);

    let parsedTx = new Transaction(xdr, Networks.TESTNET);
    let publicKey = parsedTx.source;
    let server = new Server(StellarUrlTest);
    let sourceAccount;
    try {
      sourceAccount = await server.loadAccount(keypair.publicKey());
    } catch (err2) {
      return null;
    }
    parsedTx.sign(keypair);
    let x = parsedTx
      .toEnvelope()
      .toXDR()
      .toString('base64');
    return x;
  } catch (err) {
    //console.log(err)
    return null;
  }
}
