/* eslint-disable */
import React from 'react';
import {
  makeStyles, Container,

} from '@material-ui/core';
import Page from 'src/components/Page';
import Registration from "./CreateProjectComponents/Registration"
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const CreateProjectView = (props) => {
  const classes = useStyles();
  const [step, setStep] = React.useState("REGISTRATION")

  return (
    <Page
      className={classes.root}
      title="Create Project"
    >
      <Container style={{ "padding-top": "50px", "padding-bottom": "50px" }}>

        {step === "REGISTRATION" &&
          <Registration
            setStep={setStep}
            new={props.new} />}
      </Container>
    </Page >
  );
};

export default CreateProjectView;
