// /* eslint-disable */

import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import {
    makeStyles,Typography
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


export default function MessageLoader2({ message, type }) {
    const classes = useStyles();
    if (type === "0") {
        return (
            <Backdrop className={classes.backdrop} open={true} >
                <img alt="Loading" src="https://niftron-logo.s3.amazonaws.com/NIFTRON+LOGO+SINGLE" height="200" id="breathing-logo" />
                <Box
                    color="white"
                    position="fixed"
                    top={75}
                ><Typography color="textPrimary" variant="h5"> {message}</Typography>
                </Box>
            </Backdrop>
        );
    } else {
        return (
            <Box
                bgcolor="grey.700"
                color="white"
                p={1}
                position="fixed"
                // bottom={10}
                // left={10}
                top={67}
                right={2}
            // zIndex="tooltip"
            >
                {message} <CircularProgress color="white" size={12} />
            </Box>
        );
    }

}